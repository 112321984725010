<template>
  <!-- 碳预测 -->
  <div class="carbon-prediction">
    <div class="peak public-chunk">
      <div class="public-title">
        <div class="box">
          {{globalObj['CAR-FORECAST']&&globalObj['CAR-FORECAST'].dataName|| 碳预测}}
          <div class="animation">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
      <div class="peak-main">
        <!-- <p class="unit">单位：万吨CO2</p> -->
        <div class="compare">
          <!-- <div class="carbon-peak"> -->
          <!-- <h4>碳达峰</h4> -->
          <!-- <div>
              <span>{{ globalObj['left-1-DFNF'] && globalObj['left-1-DFNF'].value || '--' }}</span>年
          </div>-->
          <!-- </div> -->
          <div class="carbon-emission">
            <div>
              <span v-if="number==0">0</span>
              <span v-else v-scroll-number="number||0"></span>

              <img src="../../../assets/images/carbon-emission.png" alt />
              <p>碳排放</p>
              <p class="p1">(单位：万tCO2)</p>
            </div>
            <div>
              <span class="s2" v-if="number1==0">0</span>
              <span class="s2" v-else v-scroll-number="number1||0"></span>
              <img src="../../../assets/images/carbon-uptake.png" alt />
              <p>碳吸收</p>
              <p class="p1">(单位：万tCO2)</p>
            </div>
          </div>
          <div class="strength">
            <div class="box">
              <h2>
                {{this.globalObj["CAR-FORECAST"] &&
                this.globalObj["CAR-FORECAST"].value.peakYear}}
              </h2>
              <p>达峰年</p>
            </div>
            <!-- <div class="line"></div> -->
            <div class="box">
              <h2 class="h2">
                {{this.globalObj["CAR-FORECAST"] &&
                this.globalObj["CAR-FORECAST"].value.neutralizeYear}}
              </h2>
              <p>中和年</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="carbon-neutral public-chunk">
      <div class="public-title head">
        <div class="box">
          <span>
            {{this.globalObj["PJ-UPTAKE"] &&
            this.globalObj["PJ-UPTAKE"].dataName}}
          </span>
          <div class="animation">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
        <span class="unit">单位：{{ globalObj['PJ-UPTAKE'] && globalObj['PJ-UPTAKE'].unit }}</span>
      </div>
      <LineChart :className="'carbon-neutral-chart'" :isSmooth="true" :chartsData="carbonNeutral" :height="'245px'" :areaStyle="['#3dfd83']" :gridTop="42" :unit="globalObj['left-2-TZH'] && globalObj['left-2-TZH'].unit" />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
export default {
  components: { LineChart },
  props: {
    globalObj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      data: {},
      number: 0,
      number1: 0,
      carbonNeutral: {
        label: [],
        legend: [],
        data: []
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.data = this.globalObj["TOTAL-EMISSION"]?.value;
      const regex = /(\d+(\.\d+)?)/; // 匹配数字及小数点
      const match = this.data && this.data.totalEmission.match(regex);
      if (match) {
        this.number = parseFloat(match[0]);
      }
      const match1 =
        this.globalObj["CAR-FORECAST"] &&
        this.globalObj["CAR-FORECAST"].value.carbonAssimilate.match(regex);
      if (match1) {
        this.number1 = parseFloat(match1[0]);
      }
      const data =
        this.data &&
        this.data.emissionIntensities.map(item => {
          return item.totalEmission;
        });
      const label =
        this.data &&
        this.data.emissionIntensities.map(item => {
          return item.year;
        });
      this.carbonNeutral = {
        data: [data],
        label: label,
        legend: []
      };
    }
  },
  watch: {
    globalObj: {
      handler() {
        this.init();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
// 碳预测
.peak {
  height: 359px;
  margin-bottom: 10px;

  .peak-main {
    height: 188px;
    padding: 0 30px;
    .unit {
      text-align: right;
      font-size: 12px;
      color: #c4f6ee;
      margin: 20px 0 10px 0;
    }

    .compare {
      display: flex;
      flex-direction: column;
      > div {
        width: 270px;
        height: 118px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .carbon-peak {
        font-size: 14px;
        font-weight: 600;
        color: #defffa;

        h4 {
          height: 14px;
          line-height: 14px;
          color: #defffa;
          margin-bottom: 10px;
        }

        span {
          font-size: 50px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #3dfd83;
          line-height: 50px;
          margin-right: 10px;
        }
      }

      .carbon-emission {
        width: 280px;
        height: 154px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 36px auto 0px;
        > div {
          text-align: center;
          &:nth-child(1) {
            margin-right: 52px;
          }
        }
        span {
          display: block;
          font-weight: bold;
          // padding-top: 10px;
          font-size: 24px;
          font-family: D-DIN-PRO-ExtraBold, D-DIN-PRO;
          font-weight: 800;
          color: #c92d21;
          background: linear-gradient(360deg, #ffdb33 0%, #fff8c0 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .s2 {
          background: linear-gradient(180deg, #b6ffdf 0%, #00b868 100%);
          color: #c92d21;
          -webkit-background-clip: text;
        }
        img {
          width: 64px;
          height: 62px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c4f6ee;
        }
        .p1 {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d3dede;
          margin-top: 8px;
        }
      }

      .strength {
        width: 280px;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        .line {
          width: 1px;
          height: 48px;
          border: 1px solid;
          border-image: linear-gradient(
              135deg,
              rgba(112, 255, 185, 0),
              rgba(87, 254, 158, 1),
              rgba(61, 253, 131, 0)
            )
            1 1;
        }
        .box {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .box:nth-child(1) {
          margin-right: 52px;
        }
        h2 {
          font-size: 34px;
          font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
          font-weight: 600;
          color: #ffef21;
          line-height: 34px;
        }
        .h2 {
          color: #3dfd83;
        }
      }
    }
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 600;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #d6ffff;
  }

  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
  }
}

.carbon-neutral {
  width: 438px;
  height: 295px;
  margin-bottom: 20px;
  background-image: url("../../../assets/images/page1-item2.png");
  background-size: cover;
}
</style>