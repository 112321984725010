<template>
  <!-- 路径管控（2023年） -->
  <div class="path-control">
    <div class="control public-chunk">
      <div class="public-title">
        <div class="box">
          <span>{{
            globalObj["TOTAL-UPTAKE"] && globalObj["TOTAL-UPTAKE"].dataName
          }}</span>
          <div class="animation">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
      <div class="control-main">
        <div class="item">
          <div class="div1">
            <img src="../../../assets//images/control1.png" alt />
            <span>
              {{
                globalObj["TOTAL-UPTAKE"] &&
                globalObj["TOTAL-UPTAKE"].value.uptakeRate
              }}
            </span>
            <p>(百分比)</p>
          </div>
          <p class="p1">碳吸收效率</p>
          <p>
            较2022同比
            <span>
              {{
                globalObj["TOTAL-UPTAKE"] &&
                globalObj["TOTAL-UPTAKE"].value.velocityYearOnYear
              }}
            </span>
            <img src="@/assets/images/icon/boom.png" alt />
          </p>
        </div>
        <div class="item">
          <div class="div1">
            <img src="../../../assets//images/control1.png" alt />
            <span>1000</span>
            <p>（万tCO2/年）</p>
          </div>
          <p class="p1">碳吸收速率</p>
          <p>
            较2022同比
            <span class="s1">
              {{
                globalObj["TOTAL-UPTAKE"] &&
                globalObj["TOTAL-UPTAKE"].value.rateYearOnYear
              }}
            </span>
            <img src="@/assets/images/icon/top.png" alt />
          </p>
        </div>
      </div>

      <div class="charts">
        <div class="herd">
          <span class="s1"></span>
          <span>碳吸收变化趋势</span>
        </div>
        <LineChart
          :className="'carbon-neutral-chart'"
          :chartsData="carbonNeutral"
          :isSmooth="true"
          :height="'240px'"
          :areaStyle="['#3dfd83']"
          :gridTop="42"
          :unit="globalObj['left-2-TZH'] && globalObj['left-2-TZH'].unit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/Charts/ProgressBar.vue";
import LineChart from "@/components/Charts/LineChart.vue";
export default {
  components: { ProgressBar, LineChart },
  props: {
    globalObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      active: 1,
      carbonNeutral: {},
      data: [
        { name: "能源", value: "52.1%", general: 13, done: 7 },
        { name: "工业与建筑", value: "75%", general: 4, done: 3 },
        { name: "服务业与其他", value: "34%", general: 3, done: 1 },
        { name: "交通运输", value: "50%", general: 2, done: 1 },
        { name: "居民生活", value: "100%", general: 2, done: 2 },
      ],
    };
  },
  watch: {
    globalObj: {
      handler(val) {
        if (this.globalObj["UPTAKE-TREND"]) {
          this.data = this.globalObj["UPTAKE-TREND"]?.value;
          this.data.legend = [];
          this.carbonNeutral = this.data;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
// 碳预测
* {
  transition: all 0.2s ease-in-out;
}
/* 定义动画 */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 设置样式 */
.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f1f1f1;
  position: relative;
}

.control {
  margin-bottom: 10px;
  width: 438px;
  height: 555px;
  background: url("../../../assets/images/boxline.png");
  .control-main {
    width: 336px;
    height: 183px;
    display: flex;
    justify-content: space-between;
    margin: 37px auto 0px;

    > .item {
      width: 130px;
      height: 183px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      > .div1 {
        width: 130px;
        height: 130px;
        background-position: center;
        background-size: cover;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          position: absolute;
          animation: rotate 1s linear infinite; /* 使用`rotate`动画名称，持续时间2s，线性变化，无限循环 */
        }
        span {
          font-size: 30px;
          font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
          font-weight: 600;
          color: #3dfd83;
          line-height: 24px;
          margin-bottom: 7px;
        }
        p {
          width: 100%;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #c4f6ee;
          line-height: 17px;
          > span {
            color: #53ffb8;
          }
        }
      }
      //.div1::before {
      //  content: "";
      // position: absolute;
      // top: 4px;
      // left: -1px;
      // width: 128px;
      // height: 130px;
      // border-radius: 50%;
      // border: 2px solid rgba(61, 253, 131, 1);
      // box-shadow: 0 0 5px 1px rgba(61, 253, 131, 1);
      //animation: rotate 5s infinite linear; /* 应用旋转动画 */
      //}
      > span {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c4f6ee;
        line-height: 14px;
      }
      .p1 {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c4f6ee;
        margin-top: 8px;
      }
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        width: 150px;
        text-align: center;
        color: #c4f6ee;
        img {
          margin-left: 5px;
        }
        span {
          color: #53ffb8;
          font-size: 16px;
        }
        .s1 {
          color: #ff5732;
          font-size: 16px;
        }
      }
    }
  }
  .charts {
    width: 404.5px;
    height: 223.5px;
    margin: 40px auto 0px;
    > .herd {
      display: flex;
      align-items: center;
      > span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d6ffff;
        line-height: 16px;
      }
      .s1 {
        display: block;
        width: 2px;
        height: 14px;
        background: linear-gradient(180deg, #70ffb9 0%, #338e54 100%);
        border-radius: 2px;
        margin-right: 8px;
      }
    }
  }
}

.emphasis {
  height: 227px;
  margin-bottom: 20px;
  padding: 16px 30px;

  .btns {
    margin-bottom: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 116px;
      height: 28px;
      background: rgba($color: #fff, $alpha: 0.2);
      border-radius: 4px;
      font-size: 14px;
      color: #defffa;
      cursor: pointer;

      &:nth-child(1) {
        margin-right: 20px;
      }
    }

    > span:hover,
    .active {
      background: #3dfd83;
      color: #0e3a35;
    }
  }
}
</style>
