<!--  -->
<script setup>
import { onMounted } from "@vue/runtime-core";
import { Delete } from "@element-plus/icons-vue";
// import mapBase from "../../assets/js/map";
import mapBase from "./map.vue";
import { ref, reactive, watchEffect } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import HomeView from "@/components/HomeView/index.vue";
import SacleBox from "@/components/SacleBox/index.vue";
import Header from "@/components/Header/index.vue";
import PeakingPrediction from "@/components/peakingPrediction/index.vue";
import EmissionStatus from "@/components/emissionStatus/index.vue";
import DynamicEvaluation from "@/components/dynamicEvaluation/index.vue";
import { ElMessage } from "element-plus";
let map;

/*区域颜色*/
let areaVal = ref(false);
const areaChange = (_) => {
  map.initCadLayer();
};

/*鼠标拾取*/
let mouseVal = ref(false);
const mouseChange = (_) => {
  map.initPick(mouseVal.value);
};

/*卫星地图*/
let roadValWx = ref(false);
const roadChangeWx = (_) => {
  let layer = document.getElementsByClassName("RoadLayerBox");
  if (layer.length !== 0) {
    if (roadValWx.value) layer[0].style.display = "block";
    else layer[0].style.display = "none";
  } else map.initSatelliteMap();
};

/*道路*/
let roadVal = ref(false);
const roadChange = (_) => {
  let layer = document.getElementsByClassName("RoadLayerBox");
  if (layer.length !== 0) {
    if (roadVal.value) layer[0].style.display = "block";
    else layer[0].style.display = "none";
  } else map.initDaolu();
};

/*热力图*/
let heatVal = ref(false);
const heatChange = (_) => {
  axios({
    url: "/Noise/ljzs/show/caseTInfo/queryCaseByYear",
    params: {
      year: 2021,
      quarter: "",
      month: 6,
    },
  }).then((res) => {
    let cs = res.data.data;
    let data = [];
    cs.forEach((v) => {
      data.push({
        value: 1,
        latitude: v.caseYPoint,
        longitude: v.caseXPoint,
      });
    });
    map.initHeatLayer({ data: data });
  });
};

/*反距离权重插值*/
let idwVal = ref(false);
const idwChange = (_) => {
  let res = require("../../../static/json/city_air.json");
  map.initIdwLayer(res.features);
};

/*风场*/
let windVal = ref(false);
const windChange = (_) => {
  map.initWindLayer();
};

/*流向*/
let flowVal = ref(false);
const flowChange = (_) => {
  map.initFlowLayer();
};

/*微站*/
const getStationHourMax = (_) => {
  axios({
    url: "/air/cgi/monitoring/monStationHour/getStationHourMax",
  }).then((res) => {
    let time = res.data.data;
    axios({
      url: "/air/cgi/monitoring/airQualityAnalysis/getHourMonitorDataMiNi",
      params: {
        startDate: time.monitoringTime,
        endDate: time.monitoringTime,
        type: "010",
        districtIds: 1000003,
      },
    }).then((res) => {
      let data = res.data.data;
      map.addStation(data, true);
    });
  });
};

/*企业渲染功能风险污染源数据*/
const getPollutionSourceByLabelCode = (_) => {
  let res = require("../../../static/json/pollutionSource.json");
  //全部不选中
  // map.removeLayer("pollutionSourceLayer");
  let options = {
    layerName: "pollutionSourceLayer",
    data: res.data,
    cluster: true,
    imgPathPrefix: "J",
    imgPath: "sxt-img.gif",
    iconSize: [30, 30],
    popupAnchor: [0, 0],
    bindPopup: (i) => {
      let html = `<div class="stationBox">`;
      html += `<div class="station">
                                          <span class="fz20 fw ml10">${i.name}</span>
                                          </div>
                                          <div class="stationInfo displayFx fC jCA">
                                           <div class="displayFx">
                                              <div style="flex-shrink: 0">
                                                <span class="fz18 colorB ">类型：</span>
                                              </div>
                                              <div>
                                                <span class="fz18 colorB">${i.labelName}</span>
                                              </div>
                                              </div>
                                             <div class="displayFx" style="justify-content: left;">
                                                <span class="fz18" style="flex-shrink: 0">地址：</span>
                                                <span class="fz18">${i.roadNo}</span>
                                            </div>
                                            </div>`;
      html += `</div>`;

      return html;
    },
  };

  map.addRiskSourceLayer(options);
};

let findOne = reactive({});
let gap = ref(0);
let id = ref(0);
const changeStationAroundSourceByDistance = (stationId) => {
  gap = map.getGap() * 1000;
  id = stationId;
  findOne = map.GSMonitordata.find((i) => i.stationId == stationId);
  getStationAroundSourceByDistance();
};

const getStationAroundSourceByDistance = (_) => {
  let distanceObj = {
    longitude: findOne.longitude,
    latitude: findOne.latitude,
    gap: gap,
    primaryPollutant: findOne.primaryPollutant,
    level: findOne.aqiLevel,
    ljStationId: id,
    stationId: id,
    labelType: "A",
    monitorTime: findOne.monitoringTime,
  };
  map.loadSourceLayer(distanceObj);
};

onMounted((_) => {
  window.changeStationAroundSourceByDistance = changeStationAroundSourceByDistance;
});

let navActive = ref(1);
const name = ref("");
const navChange = (index) => {
  navActive.value = index;
};
let data = ref([]);
const sumGetData = async () => {
  let queryParams = window.location.search.substr(1);
  const queryParamsObject = {};
  if (queryParams) {
    // 将查询参数解析成对象
    queryParams.split("&").forEach((param) => {
      const [key, value] = param.split("=");
      queryParamsObject[key] = decodeURIComponent(value);
    });
    sessionStorage.setItem("token", queryParamsObject.token);
  }
  await axios({
    url: "https://government.etantong.com/government/configurationDetail/fetch-config",
    method: "get",
    headers: {
      token:
        sessionStorage.getItem("token") ||
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
    },
  }).then((res) => {
    if (res.data.status == 550) {
      ElMessage({
        message: res.data.error,
        type: "error",
      });
    } else {
      name.value = res.data.data.name;
      data.value = res.data.data.detailMaps;
      sessionStorage.setItem("data", JSON.stringify(data.value));
    }
  });
};
const route = useRoute();
const industry = ref("");
const industryFn = (data) => {
  industry.value = data;
};
sumGetData();
</script>

<template>
  <div class="map-box">
    <div class="main-box">
      <!-- <img src="../../assets/images/backg.png" class="back" /> -->
      <SacleBox style="overflow: hidden">
        <div class="titBox"></div>
        <!-- <img src="../../assets/images/backg.png" class="back" /> -->
        <div class="backbox" :class="[route.path == '/DynamicEvaluation' ? 'backbox2' : '']"></div>
        <div class="backbox backbox1"></div>
        <mapBase class="fixe" :industry="industry"></mapBase>
        <div class="main-wraper">
          <Header @navChange="navChange" :name="name" />
          <router-view></router-view>
        </div>
        <div class="bottomLint" :class="[route.path == '/DynamicEvaluation' || route.path == '/EmissionStatus' ? 'bottomLint1' : '']">
          <div>
            <p>低碳</p>
            <span class="s1"></span>
          </div>
          <div>
            <p>低中碳</p>
            <span class="s2"></span>
          </div>
          <div>
            <p>中碳</p>
            <span class="s3"></span>
          </div>
          <div>
            <p>中高碳</p>
            <span class="s4"></span>
          </div>
          <div>
            <p>高碳</p>
            <span class="s5"></span>
          </div>
        </div>
        <div class="titBox1"></div>
      </SacleBox>
    </div>
  </div>
</template>

<style lang="scss">
*,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bottomLint1 {
  bottom: 55px !important;
}
.bottomLint {
  position: absolute;
  width: 340px;
  height: 25px;
  z-index: 999;
  bottom: 10px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);

  > div {
    width: 68px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d6ffff;
      line-height: 17px;
    }
    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #3dfd83;
    }
    .s1 {
      border-radius: 50px 0 0 50px;
    }
    .s2 {
      background-color: #09e8f8;
    }
    .s3 {
      background-color: #f3e141;
    }
    .s4 {
      background-color: #ffb826;
    }
    .s5 {
      background-color: #ff5732;
      border-radius: 0px 50px 50px 0px;
    }
  }
}
.main-box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: 100% 100%;
  background: #0c170a;
  //background: linear-gradient(to right, black, white, black);
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  .titBox {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    z-index: 3;
    background: linear-gradient(to bottom, black, rgba(255, 255, 255, 0));
  }
  .titBox1 {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    z-index: 3;
    background: linear-gradient(to top, black, rgba(255, 255, 255, 0));
  }
  .backbox {
    width: 467px;
    height: 100%;
    background: linear-gradient(to right, black, rgba(255, 255, 255, 0));
    position: absolute;
    z-index: 2;
    opacity: 0.7;
  }
  .backbox2 {
    width: 100px;
  }
  .backbox1 {
    right: 0;
    background: linear-gradient(to left, black, rgba(255, 255, 255, 0));
  }

  .fixe {
    position: fixed;
    z-index: 1;
	left:0;
	right:0;
	top:0;
	bottom:0;
  }
  .main-wraper {
    height: 1080px;
    width: 1920px;
    margin: 0 auto;

    background-repeat: no-repeat;
    background-size: 100% 100%;
    background: linear-gradient(to right, black, white, black) !important;
    color: #fff;
  }
}
.back {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  opacity: 1;
  pointer-events: none; /* 禁用蒙版的鼠标事件 */
}
@keyframes highlight {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
// 公共样式
.animation {
  display: flex;
  width: 30px;
  span {
    display: block;
    animation: highlight 0.6s infinite;
    opacity: 0.4;
    color: #3dfd83 !important;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:last-child {
    animation-delay: 0.4s;
  }
}

// 块样式
.public-chunk {
  width: 438px;
  background-image: url("../../assets/images/box.png");
  background-size: cover;
  border-image: linear-gradient(165deg, rgba(0, 208, 129, 0), #00cf7e) 1 1;
  margin-bottom: 12px;
}

.public-title {
  width: 100%;
  height: 40px;
  position: relative;
  font-weight: 600;
  color: #d6ffff;
  padding: 0px 16px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #d6ffff;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 10px;
  > .box {
    display: flex;
    align-items: center;
    .animation {
      margin-left: 8px;
    }
  }
}

.map-box {
  position: relative;
  width: 100%;
  height: 100vh;

  .main-box {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  width: 440px;
  height: 250px;
  background: linear-gradient(180deg, #035e59 0%, #01302d 100%);
  border: 1px solid #0c7972;
  color: #fff;
}

.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border: none !important;
}

.stationIconDiv {
  width: 54px;
  color: #ffffff;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mStationIconDiv {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn > div {
  margin-right: 3px;
}

.btn .stationInfoBtn {
  background-color: #2760b7;
  width: 60px !important;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 2px;
}

.btn .toStationScreenBtn {
  background-color: #2760b7;
  width: 360px;
  display: inline-block;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 5px 10px;
  font-size: 18px;
  border-radius: 2px;
  height: 35px;
}
</style>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  position: absolute;

  .fc {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999999;
    color: #fff;

    span {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  :deep(.leaflet-editing-icon) {
    border-radius: 50%;
    background-color: #ffbf4b;
  }

  :deep(.leaflet-control-velocity) {
    color: #fff;
    position: fixed;
    top: 149px;
    right: 10%;
    font-size: 16px;
  }
}
</style>
