<template>
  <div class="page1 public-chunk" v-if="show">
    <div class="public-title pad">
      <div class="box">
        <span class="s1">{{title.title}}</span>
        <span>{{title.title1}}</span>
        <div class="animation">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
    <p>(单位：万tCO2)</p>
    <div class="main">
      <LineCharts :className="'departmental-characteristics-line-chart'" :height="'180px'" width="402px" :lineColos="['#3dfd83','#09e8f8','#f3e141']" :chartsData="chartsData" :legendIcon="'square'" />
    </div>
  </div>
</template>z
  
  <script>
import LineCharts from "../Charts/LineChart.vue";
export default {
  components: { LineCharts },
  props: {
    title: {
      type: Object,
      default: () => {}
    },
    popupX: {
      type: String,
      default: "50%"
    },
    popupY: {
      type: String,
      default: "50%"
    },
    show: {
      type: Boolean,
      default: false
    },
    chartsData: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    close() {
      this.$emit("close", false);
    }
  }
};
</script>
  
  <style lang="scss" scoped>
.close {
  cursor: pointer;
}
.page1 {
  padding: 0px 16px;
  z-index: 9999;
  width: 436px;
  height: 264px;
  background: linear-gradient(
    359deg,
    rgba(2, 68, 50, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      165deg,
      rgba(0, 208, 129, 0),
      rgba(0, 207, 126, 1)
    )
    1 1;
  .pad {
    padding: 0px;
    height: 41px;
  }
  .box {
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c4f6ee;
      line-height: 20px;
    }
    .s1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #d6ffff;
      line-height: 20px;
      margin-right: 16px;
      max-width: 290px;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*禁止换行*/
      text-overflow: ellipsis; /*省略号*/
    }
  }
  > p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 12px;
    text-align: right;
  }
}
</style>