<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
const echarts = require("echarts");
import resize from "./resize";

const animationDuration = 1000;
import { toRaw } from "vue";

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: "lineChart"
    },
    // 图表宽
    width: {
      type: String,
      default: "100%"
    },
    // 图表高
    height: {
      type: String,
      default: "263px"
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          label: ["2020", "2030", "2040", "2050", "2060"],
          legend: ["碳排值", "碳吸收"],
          data: [
            [5900, 11800, 12000, 8000, 2000],
            [1200, 3000, 6000, 7500, 11000]
          ]
        };
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ["#F3E141", "#3DFD83", "#00FBE7", "#DE8800"];
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: "万吨CO2"
    },
    // 折线图是否平滑显示
    isSmooth: {
      type: Boolean,
      default: false
    },
    // 渐变
    areaStyle: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 图表离容器距离
    gridTop: {
      type: Number,
      default: 30
    },
    // 图例样式
    legendIcon: {
      type: String,
      default: "linear"
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    chartsData: {
      handler() {
        this.initChart();
      }
    }
  },
  methods: {
    // 计算最大值 均分刻度线
    findMaxAndRoundUp(arr) {
      if (!arr) return;
      const flatArr = arr.flat();
      const maxValue = Math.max(...flatArr);

      let newMax = Number(String(maxValue).charAt(0))
        ? Number(String(maxValue).charAt(0)) + 1
        : 0;
      for (let i = 0; i < String(parseInt(maxValue)).length - 1; i++) {
        newMax += "0";
      }

      return newMax || 100;
    },
    // 生成Series
    createSeries() {
      const arr = [];
      this.chartsData.data.forEach((item, index) => {
        const name = this.chartsData.legend[index];
        arr.push({
          name: name,
          type: "line",
          data: item,
          animationDuration,
          smooth: this.isSmooth,
          lineStyle: {
            width: 1.5
          },
          areaStyle: this.areaStyle.length
            ? {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: this.areaStyle[index]
                        ? this.areaStyle[index]
                        : "rgba(39, 194, 143, 0)"
                    }, // 渐变起始颜色
                    { offset: 1, color: "rgba(0, 0, 0, 0)" } // 渐变结束颜色
                  ]
                }
              }
            : null,
          //
          markLine:
            this.className === "carbon-neutral-chart"
              ? {
                  symbol: [0, 0],
                  data: [
                    {
                      xAxis: 1,
                      label: {
                        show: true,
                        formatter: "达峰年\n2030",
                        backgroundColor: "#F3E141",
                        borderRadius: 4,
                        padding: [5, 13],
                        distance: 5
                      },
                      lineStyle: {
                        normal: {
                          color: "#fff",
                          type: "dashed",
                          opacity: 0.4
                        }
                      }
                    },
                    {
                      xAxis: 4,
                      label: {
                        show: true,
                        formatter: "净零排放碳中和\n2060",
                        backgroundColor: "#3DFD83",
                        borderRadius: 4,
                        padding: [5, 13],
                        distance: 5
                      },
                      lineStyle: {
                        normal: {
                          color: "#fff",
                          type: "dashed",
                          opacity: 0.4
                        }
                      }
                    }
                  ]
                }
              : this.className === "peaking-prediction-line-chart"
              ? {
                  symbol: [0, 0],
                  data: [
                    {
                      xAxis: 2,
                      label: {
                        show: true,
                        formatter: "达峰年\n2030",
                        backgroundColor: "#F3E141",
                        borderRadius: 4,
                        padding: [5, 13],
                        distance: 5
                      },
                      lineStyle: {
                        normal: {
                          color: "#fff",
                          type: "dashed",
                          opacity: 0.4
                        }
                      }
                    }
                  ]
                }
              : {}
        });
      });
      return arr;
    },
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      const _this = this;
      // 定义自动轮播的数据项和索引
      const data = this.chartsData.label;
      let currentIndex = 0;
      toRaw(this.chart).setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(3, 94, 89, 0.8)",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: function(params) {
            let units = {
              GDP: "　",
              人口总量: "万人",
              能源消耗总量: "万吨标煤",
              碳排放总量: "万吨CO2"
            };
            var result = params[0].axisValue + "<br>"; // 添加标题 "2016"
            params.forEach(function(item) {
              // 使用 <span> 标签包裹 item.value，添加样式为红色
              result += `${item.marker} ${
                params.length === 1 ? item.name : item.seriesName
              }: 
              <span style="color: #3DFD83;font-size: 16px;margin-right: 10px;"> ${
                item.value
              }</span>
              ${
                units[item.seriesName] ? units[item.seriesName] : _this.unit
              }<br>`;
            });
            return result;
          }
        },
        legend: {
          bottom: 16,
          right: 16,
          itemWidth: _this.legendIcon === "square" ? 12 : 20,
          itemHeight: _this.legendIcon === "square" ? 12 : 2,
          icon: "roundRect",
          textStyle: {
            color: "#fff", // 图例文本颜色
            fontSize: 12 // 图例文本字体大小
          },
          data: _this.chartsData.legend
        },
        color: _this.colors,
        grid: {
          top: _this.gridTop,
          left: "2%",
          right: 30,
          bottom: _this.chartsData.legend.length ? "44" : "8",
          containLabel: true,
          show: true,
          borderColor: "none"
        },
        xAxis: [
          {
            type: "category",
            data: _this.chartsData.label,
            axisbar: {
              barStyle: {
                opacity: 0
              }
            },
            axisLine: false,
            axisLabel: {
              lineHeight: 25,
              textStyle: {
                color: "#fff" // 坐标值得具体的颜色
              },
              interval: 0,
              rotate: 0
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            max: _this.findMaxAndRoundUp(_this.chartsData?.data),
            interval: parseInt(
              _this.findMaxAndRoundUp(_this.chartsData?.data) / 6
            ),
            axisLabel: {
              color: "#fff"
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: ["rgba(214, 255, 255, 0.2)"]
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(214, 255, 255, 0.1)"]
              }
            }
          }
        ],
        series: _this.createSeries()
      });

      // 自动轮播tooltip和鼠标悬停效果
      // setInterval(() => {
      //   // 切换到下一个数据项
      //   currentIndex = (currentIndex + 1) % data.length

      //   // 高亮当前数据项
      //   this.chart?.dispatchAction({
      //     type: 'highlight',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   })

      //   // 显示当前数据项的tooltip
      //   this.chart?.dispatchAction({
      //     type: 'showTip',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   })

      //   // 延时一段时间后取消高亮和隐藏tooltip
      //   setTimeout(() => {
      //     this.chart?.dispatchAction({
      //       type: 'downplay',
      //       seriesIndex: 0,
      //       dataIndex: currentIndex
      //     })
      //     this.chart?.dispatchAction({
      //       type: 'hideTip'
      //     })
      //   }, 2500) // 延时2秒后取消高亮和隐藏tooltip
      // }, 3000) // 每3秒切换一次数据项
    }
  }
};
</script>