<template>
  <!-- 左右两边的图表 -->
  <div class="left-right-chart">
    <div class="left">
      <!-- 碳预测 -->
      <CarbonPrediction :globalObj="globalObj" />

      <!-- 排放现状 -->

      <div class="emission-status public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ this.globalObj["TOTAL-EMISSION"] && this.globalObj["TOTAL-EMISSION"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <span class="unit">单位：{{ globalObj["TOTAL-EMISSION"] && globalObj["TOTAL-EMISSION"].unit }}</span>
        </div>
        <div class="forecast-chart">
          <BarCharts :className="'emission-status'" unit="吨co2/万元" :chartsData="emissionStatusData" :height="'215px'" :legendIcon="'square'" :gridTop="30" />
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 路径管控 -->
      <PathControl :globalObj="globalObj" />

      <!-- 动态评估 -->
      <DynamicEvaluation :globalObj="globalObj" />
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
import CarbonPrediction from "./components/CarbonPrediction.vue";
import PathControl from "./components/PathControl.vue";
import DynamicEvaluation from "./components/DynamicEvaluation.vue";
import BarChart from "../Charts/BarChart.vue";
import BarCharts from "../Charts/BarChartTip.vue";
import axios from "axios";
export default {
  components: {
    CarbonPrediction,
    LineChart,
    PathControl,
    DynamicEvaluation,
    BarChart,
    BarCharts,
  },
  props: ["sumGetData"],
  data() {
    return {
      data: {},
      year: new Date().getFullYear(),
      globalObj: {},
      emissionStatusData: {},
      count: 0,
    };
  },
  async created() {
    sessionStorage.setItem("year", new Date().getFullYear());
    this.time();
    // if (!this.data.list) {
    //   setTimeout(() => {
    //     const a = JSON.parse(sessionStorage.getItem("data"));
    //     this.data = a ? a[0] : {};
    //     this.getData();
    //   }, 900);
    // }
  },
  methods: {
    time() {
      setTimeout(() => {
        const a = JSON.parse(sessionStorage.getItem("data"));
        this.data = a ? a[0] : {};
        if (this.data && Object.keys(this.data).length == 0) {
          this.count++;
          if (this.count < 5) {
            this.time();
          }
        } else {
          this.getData();
          this.count = 0;
        }
      }, 500);
    },
    getData() {
      const obj = {};
      if (this.data.list && this.data.list.length > 0) {
        const promiseList = [];
        this.data.list.forEach((citem) => {
          citem.poList.forEach((gitem) => {
            gitem.key = gitem.keyValue;
            obj[gitem.key] = gitem;
            if (obj[gitem.key].type == "4") {
              const year = [this.year];
              const promise = axios
                .post("https://government.etantong.com/government/configurationDetail/fetch-data/" + obj[gitem.key].id, year, {
                  headers: {
                    // token: queryParamsObject.token
                    token:
                      sessionStorage.getItem("token") ||
                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                  },
                })
                .then((res) => {
                  obj[gitem.key].value = JSON.parse(res.data.data.data);
                });
              promiseList.push(promise);
            } else if (obj[gitem.key].type != "1" && obj[gitem.key].type != "4") {
              obj[gitem.key].value = JSON.parse(obj[gitem.key].data);
            } else {
              obj[gitem.key].value = obj[gitem.key].data;
            }
          });
        });
        Promise.all(promiseList)
          .then(() => {
            this.globalObj = obj;
            if (this.globalObj["TOTAL-EMISSION"].value) {
              const data = this.globalObj["TOTAL-EMISSION"].value.emissionIntensities.map((item) => item.totalCarbonIntensity);
              const label = this.globalObj["TOTAL-EMISSION"].value.emissionIntensities.map((item) => item.year);
              this.emissionStatusData = {
                data: [data],
                label,
              };
            }
            this.emissionStatusData.legend = [];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  text-align: right;
  font-size: 12px;
  color: #c4f6ee;
  margin: 20px 0 10px 0;
  font-weight: 400;
}

.left-right-chart {
  > .left,
  > .right {
    width: 436px;
    position: fixed;
    height: 100%;
    top: 119px;
    left: 30px;
    z-index: 10000;
  }

  .right {
    left: auto;
    right: 30px;
  }
}

.left {
  > .emission-status {
    height: 264px;
    background-image: url("../../assets/images/boxMax.png");
  }
}
</style>
