<template>
  <div class="page1 public-chunk" v-if="show">
    <div class="public-title pad">
      <div class="box">
        <span class="s1">{{ title.title }}</span>
        <span>{{ title.title1 }}</span>
        <div class="animation">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="left">
        <div class="item item-s">
          <span>碳效值</span>
          <div>
            <span class="s1" v-scroll-number="chartsData.totalEmission" v-if="chartsData"></span>
            <span v-else class="s1">----</span>
          </div>
        </div>
        <div class="item">
          <span>碳排总量</span>
          <div>
            <span class="s1" v-scroll-number="chartsData.totalEmissionStrength" v-if="chartsData"></span>
            <span v-else class="s1">----</span>

            <span>万吨CO2</span>
          </div>
        </div>
        <div class="item">
          <span>单位GDP碳排强度</span>
          <div>
            <span class="s1" v-scroll-number="chartsData.totalEnergy" v-if="chartsData"></span>
            <span v-else class="s1">----</span>
            <span>tCO2/万元</span>
          </div>
        </div>
        <div class="item">
          <span>行业碳排强度均值</span>
          <div>
            <span class="s1" v-scroll-number="chartsData.totalEnergyStrength" v-if="chartsData"></span>
            <span v-else class="s1">----</span>
            <span>tCO2/万元</span>
          </div>
        </div>
      </div>
      <div class="rigth">
        <img :src="code.img" class />
        <div class="box">
          <span>{{ code.codeStatus }}</span>
          <span class="line"></span>
          <span>{{ code.code1 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineCharts from "../Charts/LineChart.vue";
import code0 from "../../assets/images/icon/code0.png";
import code1 from "../../assets/images/icon/code1.png";
import code2 from "../../assets/images/icon/code2.png";
import code3 from "../../assets/images/icon/code3.png";
import code4 from "../../assets/images/icon/code4.png";
import code5 from "../../assets/images/icon/code5.png";

export default {
  components: { LineCharts },
  props: {
    title: {
      type: Object,
      default: () => {},
    },
    popupX: {
      type: String,
      default: "50%",
    },
    popupY: {
      type: String,
      default: "50%",
    },
    show: {
      type: Boolean,
      default: false,
    },
    chartsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: 0,
      code0: code0,
      code1: code1,
      code2: code2,
      code3: code3,
      code4: code4,
      code5: code5,
    };
  },

  methods: {
    close() {
      this.$emit("close", false);
    },
  },
  computed: {
    code() {
      if (this.chartsData && this.chartsData.codeLevel === 1) {
        return { codeStatus: "绿码", code1: "低碳", img: code1 };
      } else if (this.chartsData && this.chartsData.codeLevel === 2) {
        return { codeStatus: "蓝码", code1: "低中碳", img: code2 };
      } else if (this.chartsData && this.chartsData.codeLevel === 3) {
        return { codeStatus: "黄码", code1: "中碳", img: code3 };
      } else if (this.chartsData && this.chartsData.codeLevel === 4) {
        return { codeStatus: "橙码", code1: "中高碳", img: code4 };
      } else if (this.chartsData && this.chartsData.codeLevel === 5) {
        return { codeStatus: "红码", code1: "高碳", img: code5 };
      } else {
        return { codeStatus: "无码", code1: "无码", img: code0 };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  cursor: pointer;
}
.page1 {
  padding: 0px 16px;
  z-index: 9999;
  width: 446px;
  height: 303px;
  background: linear-gradient(359deg, rgba(2, 68, 50, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(165deg, rgba(0, 208, 129, 0), rgba(0, 207, 126, 1)) 1 1;
  .pad {
    padding: 0px;
    height: 41px;
  }
  .box {
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c4f6ee;
      line-height: 20px;
    }
    .s1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #d6ffff;
      line-height: 20px;
      margin-right: 16px;
    }
  }
  .main {
    display: flex;
    position: relative;
    .left {
      .item-s {
        background: linear-gradient(97deg, #20442e 0%, rgba(11, 36, 31, 0.2) 100%, rgba(11, 36, 31, 0.2) 100%);
      }
      .item-s {
        height: 68px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background: linear-gradient(97deg, #20442e 0%, rgba(11, 36, 31, 0.2) 100%, rgba(11, 36, 31, 0.2) 100%);
        div {
          .s1 {
            font-size: 34px !important;
            font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
            font-weight: 600;
            color: #3dfd83;
            margin-right: 8px;
            overflow: hidden; /*超出部分隐藏*/
            white-space: nowrap; /*禁止换行*/
            text-overflow: ellipsis; /*省略号*/
            max-width: 302px;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #d3dede;
            line-height: 12px;
          }
        }
      }
      .item {
        padding-left: 16px;
        width: 397px;
        height: 38px;
        border-radius: 4px;
        margin: 8px 0px;
        > span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #d6ffff;
          line-height: 14px;
        }
        div {
          .s1 {
            font-size: 16px;
            font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
            font-weight: 600;
            color: #3dfd83;
            margin-right: 8px;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #d3dede;
            line-height: 12px;
          }
        }
      }
    }
    .rigth {
      position: absolute;
      right: 0px;
      top: 0px;
      perspective: 800px; /* 设置透视视角 */
      .box {
        width: 140px;
        height: 40px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: linear-gradient(359deg, rgba(28, 109, 62, 1) 0%, rgba(0, 0, 0, 0.7) 100%);
        .line {
          width: 2px;
          height: 14px;
          background: linear-gradient(180deg, #70ffb9 0%, #338e54 100%);
          border-radius: 2px;
        }
        span {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #d6ffff;
          line-height: 20px;
        }
      }
      .img {
        animation: rotate 5s infinite linear; /* 应用旋转动画 */
        transform-style: preserve-3d; /* 保持3D效果 */
      }
      @keyframes rotate {
        0% {
          transform: rotateY(0deg); /* 初始状态，不旋转 */
        }
        100% {
          transform: rotateY(360deg); /* 旋转一周，360度 */
        }
      }
    }
  }
}
</style>
