<template>
  <!-- 左右两边的图表 -->
  <div class="left-right-chart">
    <div class="left">
      <!-- 达峰预测 -->
      <div class="peaking-prediction-line-chart1 public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["FORECAST"] && globalObj["FORECAST"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["FORECAST"] && globalObj["FORECAST"].unit }}</p>
        </div>
        <LineChart :className="'peaking-prediction-line-chart'" :height="'290px'" :chartsData="peakinPredictionData" :gridTop="42" :lineColos="['#3dfd83', '#09e8f8', '#f3e141']" :unit="globalObj['left-1-DFYC'] && globalObj['left-1-DFYC'].unit" />
      </div>

      <!-- 碳中和达峰预测 -->
      <div class="department-peaking public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["NEUTRALIZE"] && globalObj["NEUTRALIZE"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["NEUTRALIZE"] && globalObj["NEUTRALIZE"].unit }}</p>
        </div>
        <LineChart
          :className="'department-peaking-line-chart'"
          :roundPoint="true"
          :height="'245px'"
          :chartsData="departmentPeakingData"
          :areaStyle="['#2dc066', '#b5b521']"
          :lineColos="['#2dc066', '#b5b521']"
          :legendIcon="'square'"
          :unit="globalObj['left-2-BMDFFX'] && globalObj['left-2-BMDFFX'].unit" />
      </div>

      <!-- 部门达峰与基准对比（2023年）   |    各部门相对减排量贡献率（2023年） -->
      <div class="standard-and-emission-reduction public-chunk">
        <div class="reduction-left">
          <div class="public-title">
            <div class="box">
              <span>
                {{ this.globalObj["INDUSTRY-NEUTRALIZ"] && this.globalObj["INDUSTRY-NEUTRALIZ"].dataName }}
              </span>
              <div class="animation">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
            <p class="unit">单位：{{ globalObj["INDUSTRY-NEUTRALIZ"] && globalObj["INDUSTRY-NEUTRALIZ"].unit }}</p>
          </div>
          <LineChart :className="'department-peaking-line-chart'" :height="'245px'" :chartsData="leftSectoralbaselinecomparison" :legendIcon="'square'" :unit="globalObj['left-2-BMDFFX'] && globalObj['left-2-BMDFFX'].unit" />
          <!-- <div class="box">
            <div class="box-left">
              <div></div>
              <p>基准情景</p>
            </div>
            <div class="box-right">
              <div>
                <p>减排比例</p>
                <p>{{ globalObj['left-3-BMDFYJZDB-ratio'] && globalObj['left-3-BMDFYJZDB-ratio'].value }}</p>
              </div>
              <p>达峰情景</p>
            </div>
          </div>-->
        </div>
        <!-- <div class="line"></div> -->
        <!-- <div class="reduction-right">
          <p class="head">
            各部门相对减排量贡献率
            <span>（2023年）</span>
          </p>
        </div>-->
      </div>
    </div>
    <div class="right">
      <!-- 碳达峰能源消耗趋势 -->
      <div class="industry-peaking public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["ENERGY-CONSUMPTION"] && globalObj["ENERGY-CONSUMPTION"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["ENERGY-CONSUMPTION"] && globalObj["ENERGY-CONSUMPTION"].unit }}</p>
        </div>
        <LineChart
          :className="'industry-peaking-line-chart'"
          :height="'248px'"
          :chartsData="industryPeakingData"
          :areaStyle="['#36e075', '#09E8F8', '#F3E141', '#FF5732']"
          :roundPoint="true"
          :lineColos="['#36e075', '#09E8F8', '#F3E141', '#FF5732']"
          :legendIcon="'square'"
          :isSmooth="true"
          :gridTop="42"
          :unit="globalObj['right-1-GYDFYJZDB'] && globalObj['right-1-GYDFYJZDB'].unit" />
      </div>

      <!-- 工业达峰与基准对比 -->
      <div class="standard-and-emission-reduction1 public-chunk">
        <div class="reduction-left">
          <div class="public-title">
            <div class="box">
              <span>{{ globalObj["ENT-TOP"] && globalObj["ENT-TOP"].dataName }}</span>
              <div class="animation">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
          <div class="data-list">
            <div class="header">
              <span class="column1">排名</span>
              <span class="column2">企业达峰排名</span>
              <div class="column3">
                <span>碳排放</span>
                <p>（万吨CO2）</p>
              </div>
              <span class="column4">达峰年</span>
            </div>
            <div class="list-body">
              <!-- <vue-seamless-scroll class="seamless-warp" :data="dataList" :class-option="defaultOption"> -->
              <div class="list-item" v-for="(item, index) in entTop" :key="index">
                <span class="column1">{{ index + 1 }}</span>
                <el-tooltip class="box-item" effect="dark" :content="item.accountName" placement="top">
                  <span class="column2">{{ item.accountName }}</span>
                </el-tooltip>
                <span class="column3" :class="`leval${item.leval}`">{{ item.totalEmission }}</span>
                <span class="column4">{{ item.year }}</span>
              </div>
              <!-- </vue-seamless-scroll> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
import BarChartFalls from "@/components/Charts/BarChartFalls.vue";
import PieChart from "@/components/Charts/PieChart.vue";
import axios from "axios";
export default {
  components: { LineChart, BarChartFalls, PieChart },
  data() {
    return {
      data: [],
      year: new Date().getFullYear(),
      globalObj: {},
      // 达峰预测
      peakinPredictionData: {},
      // 部门达峰情景
      departmentPeakingData: {
        // label: [
        //   "2020",
        //   "2030",
        //   "2040",
        //   "2050",
        //   "2060",
        //   "2070",
        //   "2080",
        //   "2090",
        //   "2100",
        //   "2060",
        //   "2060",
        //   "2060"
        // ],
        // legend: ["碳排值", "碳吸收"],
        // data: [
        //   [
        //     5900,
        //     11800,
        //     12000,
        //     8000,
        //     2000,
        //     1000,
        //     2000,
        //     33000,
        //     4000,
        //     5000,
        //     60000,
        //     70000
        //   ],
        //   [1200, 3000, 6000, 7500, 11000, 2000, 5500, 6, 3000, 700, 800, 88]
        // ]
      },
      // 部门达峰与基准对比 left
      leftSectoralbaselinecomparison: {},
      // 各部门相对减排量贡献率 left
      leftEmissionReductionContribution: {
        // legend: [],
        // data: [
        //   { value: 720, name: "能源" },
        //   { value: 720, name: "工业与建筑" },
        //   { value: 760, name: "交通运输" },
        //   { value: 520, name: "服务业与其他" },
        //   { value: 520, name: "农业" },
        //   { value: 520, name: "居民生活" }
        // ]
      },
      // 工业达峰与基准对比（2023年）
      industryPeakingData: {
        // label: ["2010", "2020", "2030", "2040", "2050", "2060"],
        // legend: ["能源", "工业与建筑", "交通"],
        // data: [
        //   [188, 290, 355, 372, 368, 327],
        //   [307, 269, 273, 327, 358, 355],
        //   [254, 250, 302, 384, 401, 342]
        // ]
      },
      industryActive: 1,
      // 达峰能源结构占比
      energyPeakingData: {
        // label: [
        //   "2020",
        //   "2021",
        //   "2022",
        //   "2023",
        //   "2024",
        //   "2025",
        //   "2026",
        //   "2027",
        //   "2028"
        // ],
        // legend: ["煤", "电力", "天然气", "石油"],
        // data: [
        //   [14, 11, 19, 18, 22, 25, 15, 8, 10],
        //   [31, 29, 25, 23, 16, 20, 28, 17, 13],
        //   [7, 9, 13, 16, 21, 31, 20, 27, 28],
        //   [30, 17, 9, 14, 22, 24, 12, 6, 33]
        // ]
      },
      // 部门达峰与基准对比 right
      rightSectoralbaselinecomparison: {
        // label: ["1", "2", "3", "4", "5", "6", "7", "8"],
        // legend: ["碳排值", "碳吸收", "碳吸收1"],
        // data: [
        //   [0, 32, 16, 10, 6, 3, 2, 0],
        //   [64],
        //   ["-", 32],
        //   ["-", "-", 16],
        //   ["-", "-", "-", 10],
        //   ["-", "-", "-", "-", 6],
        //   ["-", "-", "-", "-", "-", 3],
        //   ["-", "-", "-", "-", "-", "-", 2]
        // ]
      },
      // 各部门相对减排量贡献率 right
      rightEmissionReductionContribution: {
        // legend: [],
        // data: [
        //   { value: 720, name: "能源" },
        //   { value: 720, name: "工业与建筑" },
        //   { value: 760, name: "交通运输" },
        //   { value: 520, name: "服务业与其他" },
        //   { value: 520, name: "农业" },
        //   { value: 520, name: "居民生活" }
        // ]
      },
      entTop: [],
      globalObj: {},
    };
  },
  created() {
    const a = JSON.parse(sessionStorage.getItem("data"));
    this.data = a[1] || [];
    this.getData();
  },

  methods: {
    getData() {
      const obj = {};
      if (this.data && this.data.list.length > 0) {
        const promiseList = [];
        this.data.list.forEach((citem) => {
          citem.poList.forEach((gitem) => {
            gitem.key = gitem.keyValue;
            obj[gitem.key] = gitem;
            if (obj[gitem.key].type == "4") {
              const year = [this.year];
              const promise = axios
                .post("https://government.etantong.com/government/configurationDetail/fetch-data/" + obj[gitem.key].id, year, {
                  headers: {
                    // token: queryParamsObject.token
                    token:
                      sessionStorage.getItem("token") ||
                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                  },
                })
                .then((res) => {
                  obj[gitem.key].value = JSON.parse(res.data.data.data);
                });
              promiseList.push(promise);
            } else if (obj[gitem.key].type != "1") {
              obj[gitem.key].value = JSON.parse(obj[gitem.key].data);
            } else {
              obj[gitem.key].value = obj[gitem.key].data;
            }
          });
        });

        Promise.all(promiseList)
          .then(() => {
            this.globalObj = obj;
            (this.peakinPredictionData.label = this.globalObj["FORECAST"].value.label.slice(1, -1).split(",")),
              (this.peakinPredictionData.legend = JSON.parse(this.globalObj["FORECAST"].value.legend)),
              (this.peakinPredictionData.data = this.globalObj["FORECAST"].value.data);

            this.departmentPeakingData = {
              label: this.globalObj["NEUTRALIZE"].value.label.slice(1, -1).split(","),
              data: this.globalObj["NEUTRALIZE"]?.value.data,
              legend: JSON.parse(this.globalObj["NEUTRALIZE"]?.value.legend),
            };
            this.leftSectoralbaselinecomparison = this.globalObj["INDUSTRY-NEUTRALIZ"]?.value;
            this.industryPeakingData = this.globalObj["ENERGY-CONSUMPTION"]?.value;
            this.entTop = this.globalObj["ENT-TOP"].value;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-list {
  padding: 0 20px;
  height: 520px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 40px;
    margin-top: 20px;
    background: rgba(5, 217, 214, 0.15);

    span {
      font-size: 12px;
      color: #d6ffff;
    }
  }

  .column1 {
    flex: 1;
  }

  .column2 {
    flex: 4;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .column3 {
    flex: 3;
    color: #3dfd83 !important;
    p {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d3dede;
    }
  }

  .column4 {
    flex: 2;
  }

  .list-body {
    text-align: center;
    height: 430px;
    overflow: auto;

    .list-item {
      display: flex;
      height: 35px;
      span {
        height: 59px;
        line-height: 59px;
        font-size: 12px;
        color: #d6ffff;
      }

      .leval1 {
        color: #3dfd83;
      }

      .leval2 {
        color: #00c4e1;
      }

      .leval3 {
        color: #f3e141;
      }

      .leval4 {
        color: #ff8140;
      }

      .leval5 {
        color: #ee1111;
      }
    }
  }
}
.left-right-chart {
  display: flex;
  justify-content: space-between;
  > .left,
  > .right {
    width: 438px;
    position: fixed;
    top: 119px;
    left: 30px;
    z-index: 10000;
  }

  .right {
    left: auto;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

.peaking-prediction-line-chart1 {
  background: url("../../assets/images//page2-item5.png");
  width: 438px;
  height: 330px;
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}

.department-peaking {
  height: 295px;
  background: url("../../assets//images/page2-item1.png");
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}

.standard-and-emission-reduction {
  height: 301px;
  display: flex;
  justify-content: space-between;
  background: url("../../assets//images/page2-item2.png");

  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }

  .reduction-left,
  .reduction-right {
    flex: 1;

    .head {
      font-size: 14px;
      font-weight: 600;
      color: #defffa;
      padding-left: 30px;

      span {
        color: #8abfb8;
      }
    }
  }

  .line {
    height: 100%;
    border-left: 2px dashed rgba(222, 255, 250, 0.3);
  }

  .reduction-left {
    position: relative;
  }
}
.standard-and-emission-reduction1 {
  height: 645px;
  background-image: url("../../assets//images/page2-item4.png");
  background-size: cover;
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}
// 右边
.industry-peaking {
  height: 294px;
  background-image: url("../../assets/images/box3.png");
  background-size: cover;
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
  .head {
    font-size: 14px;
    font-weight: 600;
    color: #defffa;
    padding-left: 30px;
    padding-top: 20px;

    span {
      color: #8abfb8;
    }
  }
}

.energy-peaking {
  padding-top: 16px;
  height: 224px;
  margin-top: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;

    > span {
      font-size: 14px;
      font-weight: 600;
      color: #defffa;
    }

    .tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 130px;
        height: 28px;
        background: rgba($color: #ffffff, $alpha: 0.2);
        border-radius: 4px;
        font-size: 14px;
        color: #d6ffff;
        line-height: 28px;
        text-align: center;
        margin-left: 10px;
        cursor: pointer;
      }

      .active {
        background: #3dfd83;
        color: #0e3a35;
      }
    }
  }
}
</style>
