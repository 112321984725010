<!-- 排放现状 -->
<template>
  <!-- 左右两边的图表 -->
  <div class="left-right-chart">
    <div class="left">
      <!-- 碳排放现状（2023年） -->
      <div class="current-situation public-chunk">
        <div class="public-title">
          <div class="box">
            <span>
              {{ this.globalObj["emissions-overview"] && this.globalObj["emissions-overview"].dataName }}
              （{{ year }}）
            </span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
        <div class="current-situation-main">
          <div v-for="(item, index) in currentSituation" :key="index">
            <div class="name">{{ item.name }}</div>
            <div class="value">
              <!-- <span class="s1" v-if="item.value" v-scroll-number="item.value"></span> -->
              <span class="s1" v-scroll-number="item.value"></span>
              <div class="unit">{{ item.unit }}</div>
            </div>
            <div class="compare">
              较{{ year - 1 }}年同比
              <span v-if="item.compare.indexOf('-') == -1" class="s1">{{ item.compare }} {{ item.compare == 0 ? "" : "↑" }}</span>
              <span v-else class="s2">{{ item.compare }}{{ item.compare == 0 ? "" : "ↆ" }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 区域碳排月度趋势 -->
      <div class="departmental-characteristics public-chunk">
        <div class="public-title">
          <div class="box">
            <span>
              {{ this.globalObj["emissions-month"] && this.globalObj["emissions-month"].dataName }}
            </span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["emissions-month"] && globalObj["emissions-month"].unit }}</p>
        </div>
        <LineChart :className="'departmental-characteristics-line-chart'" :height="'226px'" :chartsData="departmentalCharacteristicsData" :areaStyle="['#3dfd83', '#00C4E1', '#3DFD83', '#F3E141', '#DE8800', '#952518']" :legendIcon="'square'" />
      </div>

      <!-- 行业碳排现状 -->
      <div class="energy-characteristics public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["emissions-industry"] && globalObj["emissions-industry"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["emissions-industry"] && globalObj["emissions-industry"].unit }}</p>
        </div>
        <LineChart v-if="emissionsIndustry.data && emissionsIndustry.data.length" :className="'departmental-characteristics-line-chart'" :height="'240px'" :chartsData="emissionsIndustry" :legendIcon="'square'" />
        <div v-else class="box1">暂无数据</div>
      </div>
    </div>
    <div class="right">
      <!-- 问题分析（2023年） -->
      <div class="problem-analysis public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ name[0] }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <span />
        </div>
        <div class="problem-analysis-main">
          <PieRingChart :chartsData="data1" unit="万吨" :colors="['#0FE75E', '#EAA70B', '#15F5FC', '#FAF275', '#FF5732', '#D3DEDE']" height="240px"></PieRingChart>
        </div>
      </div>

      <!-- 能源品种消耗TOP5 -->
      <div class="energy-variety public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ name[1] }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["energy-overview"] && globalObj["energy-overview"].unit }}</p>
        </div>
        <BarChart
          v-if="energyVarietyData.data && energyVarietyData.data.length"
          :className="'energy-variety-bar'"
          :height="'230px'"
          :colors="[
            { offset: 0, color: '#FFF173' },
            { offset: 0.5, color: '#FFF173' },
            { offset: 0.5, color: '#FCB138' },
            { offset: 1, color: '#FCB138' },
          ]"
          :chartsData="energyVarietyData"
          :unit="'万吨标煤'" />
        <div v-else class="box1">暂无数据</div>
      </div>

      <!-- 能源强度变化趋势 -->
      <div class="footprint-analysis public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["energy-intensity-year-line"] && globalObj["energy-intensity-year-line"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <p class="unit">单位：{{ globalObj["energy-intensity-year-line"] && globalObj["energy-intensity-year-line"].unit }}</p>
        </div>
        <LineChart :className="'departmental-characteristics-line-chart'" :height="'226px'" unit="吨标煤/万元" :chartsData="sankeyChartData" :areaStyle="['#3dfd83', '#00C4E1', '#3DFD83', '#F3E141', '#DE8800', '#952518']" :legendIcon="'square'" />
      </div>
    </div>

    <div class="footer">
      <div class="footer-main">
        <span v-for="(year, index) in past20YearsSorted" :key="year" @click="changeYear(index, year)" :class="[yearActive === index ? 'yearActive' : '']">{{ year }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
import BarChart from "../Charts/BarChartTip.vue";
import ProgressBar from "@/components/Charts/ProgressBar.vue";
import SankeyChart from "@/components/Charts/SankeyChart.vue";
import PieRingChart from "../Charts/PieRingCharts.vue";
import axios from "axios";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const setPage3Year = (data) => {
      store.commit("setPage3Year", data);
    };
    return {
      setPage3Year,
    };
  },
  components: {
    LineChart,
    BarChart,
    ProgressBar,
    SankeyChart,
    PieRingChart,
  },
  data() {
    return {
      // 碳排放现状
      currentSituation: [
        {
          name: "碳排放总量",
          value: 0,
          unit: "万吨CO2",
          compare: "1.7",
        },
        {
          name: "单位GDP碳排强度",
          value: 0,
          unit: "万吨CO2/万元",
          compare: "4.8",
        },
        {
          name: "能源消耗总量",
          value: 0,
          unit: "万吨标煤",
          compare: "2.4",
        },
        {
          name: "能耗强度",
          value: 0,
          unit: "吨标煤/万元",
          compare: "4.8",
        },
      ],
      // 部门特征
      departmentalCharacteristicsData: {},
      // 能源特征
      energyCharacteristics: {
        // legend: ["煤", "电力调入", "天然气", "石油"],
        // xdata: [
        //   "2014",
        //   "2015",
        //   "2016",
        //   "2017",
        //   "2018",
        //   "2019",
        //   "2020",
        //   "2021",
        //   "2022",
        //   "2023"
        // ],
        // result: [
        //   {
        //     name: "煤",
        //     data: [748, 1120, 1381, 919, 678, 1309, 572, 1317, 875, 1350]
        //   },
        //   {
        //     name: "电力调入",
        //     data: [1345, 614, 1311, 1026, 833, 546, 1169, 1088, 770, 606]
        //   },
        //   {
        //     name: "天然气",
        //     data: [1200, 752, 582, 1275, 1368, 1031, 697, 802, 1444, 912]
        //   },
        //   {
        //     name: "石油",
        //     data: [1379, 551, 1283, 1398, 1161, 705, 1306, 1192, 1225, 859]
        //   }
        // ]
      },
      active: 1,
      // 问题分析
      data1: [
        { value: 2, name: "调入电力" },
        { value: 3, name: "天然气" },
        { value: 7, name: "碳酸钾" },
        { value: 1, name: "煤" },
      ],
      // 能源品种消耗TOP5
      energyVarietyData: {},
      // 行业碳排现状
      emissionsIndustry: {},
      // 足迹分析
      sankeyChartData: {},
      past20Years: [],
      yearActive: 0,
      globalObj: {},
      data: [],
      name: [],
      year: new Date().getFullYear(),
    };
  },
  computed: {
    past20YearsSorted() {
      return this.past20Years.reverse();
    },
  },
  created() {
    sessionStorage.setItem("year", this.year);
    const a = JSON.parse(sessionStorage.getItem("data"));
    this.data = a[2] || [];
    this.getData();
  },
  mounted() {
    this.getPast20Years();
  },
  methods: {
    changeYear(index, year) {
      this.yearActive = index;
      this.year = year;
      sessionStorage.setItem("year", this.year);
      this.setPage3Year(year);
      this.getData(year);
    },
    getData(year = new Date().getFullYear()) {
      const obj = {};
      if (this.data.list && this.data.list.length > 0) {
        const promiseList = [];
        this.data.list.forEach((citem) => {
          citem.poList.forEach((gitem) => {
            gitem.key = gitem.keyValue;
            obj[gitem.key] = gitem;
            if (obj[gitem.key].type == "4" && obj[gitem.key].function != "enterprise-emissions-overview") {
              const years = [year];
              // years.push(obj[gitem.key].id);
              const promise = axios
                .post("https://government.etantong.com/government/configurationDetail/fetch-data/" + obj[gitem.key].id, years, {
                  headers: {
                    // token: queryParamsObject.token
                    token:
                      sessionStorage.getItem("token") ||
                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                  },
                })
                .then((res) => {
                  obj[gitem.key].value = JSON.parse(res.data.data.data);
                });
              promiseList.push(promise);
            } else if (obj[gitem.key].type != "1" && obj[gitem.key].type != "4") {
              obj[gitem.key].value = JSON.parse(obj[gitem.key].data);
            } else {
              obj[gitem.key].value = obj[gitem.key].data;
            }
          });
        });
        Promise.all(promiseList)
          .then(() => {
            this.globalObj = obj;
            this.name = this.globalObj["energy-overview"].dataName.split("&");
            const label = this.globalObj["emissions-month"].value.map((item) => item.month);
            const data = this.globalObj["emissions-month"].value.map((item) => item.totalEmission);
            this.departmentalCharacteristicsData = {
              data: [data],
              label: label,
              legend: [],
            };
            const label1 = this.globalObj["energy-intensity-year-line"].value.map((item) => item.year);
            const data1 = this.globalObj["energy-intensity-year-line"].value.map((item) => item.totalEnergyStrength);
            this.sankeyChartData = {
              data: [data1],
              label: label1,
              legend: [],
            };
            this.data1 = [];
            let numSum = 0;
            this.globalObj["energy-overview"].value.forEach((item, index) => {
              if (item.energyUsage > 0 && index <= 4) {
                const obj = {
                  value: item.energyUsage,
                  name: item.energyName,
                  itemStyle: { color: "" },
                };
                this.data1.push(obj);
              }
              if (index >= 5) {
                numSum = numSum + item.energyUsage;
              }
            });
            if (numSum > 0) {
              const obj1 = {
                value: numSum,
                name: "其他",
                itemStyle: { color: "" },
              };
              this.data1.push(obj1);
            }
            if (this.globalObj["energy-overview"].value.length >= 5) {
              const legend = [];
              for (let i = 0; i < 5; i++) {
                legend.push(this.globalObj["energy-overview"].value[i].energyName);
              }
              const result = [];
              for (let i = 0; i < 5; i++) {
                result.push(this.globalObj["energy-overview"].value[i].energyUsage);
              }

              this.energyVarietyData = {
                legend: [],
                data: [result],
                label: legend,
              };
            } else if (this.globalObj["energy-overview"].value.length != 0) {
              const legend = [];
              for (let i = 0; i < 5; i++) {
                if (this.globalObj["energy-overview"].value[i]) {
                  const str = this.globalObj["energy-overview"].value[i].energyName;
                  legend.push(str);
                }
              }
              const result = [];
              for (let i = 0; i < 5; i++) {
                if (this.globalObj["energy-overview"].value[i]) {
                  result.push(this.globalObj["energy-overview"].value[i].energyUsage);
                }
              }
              this.energyVarietyData = {
                legend: [],
                data: [result],
                label: legend,
              };
            } else if (this.globalObj["energy-overview"].value.length == 0) {
              this.energyVarietyData = {
                legend: [],
                data: [],
                label: [],
              };
            }
            // 行业碳排现状
            const emissionsIndustryLine = Object.values(this.globalObj["emissions-industry"].value);
            if (emissionsIndustryLine.length > 0) {
              let i = 0;
              const names = [];
              const years = [];
              const value = [];
              for (let key in this.globalObj["emissions-industry"].value) {
                names.push(this.globalObj["emissions-industry"].value[key][i].industryName);
                const arr = this.globalObj["emissions-industry"].value[key].map((item) => item.year);
                const arr1 = this.globalObj["emissions-industry"].value[key].map((item) => item.totalEmission);
                years.push(arr);
                value.push(arr1);
                i++;
              }
              this.emissionsIndustry = {
                legend: names,
                label: years[0],
                data: value,
              };
            } else {
              this.emissionsIndustry = {
                legend: [],
                label: [],
                data: [],
              };
            }
            // 碳排放现状
            this.currentSituation[0].value = this.globalObj["emissions-overview"].value.totalEmission;
            this.currentSituation[1].value = this.globalObj["emissions-overview"].value.totalEmissionStrength;
            this.currentSituation[2].value = this.globalObj["emissions-overview"].value.totalEnergy;
            this.currentSituation[3].value = this.globalObj["emissions-overview"].value.totalEnergyStrength;
            this.currentSituation[0].compare = this.globalObj["emissions-overview"].value.totalEmissionStrengthYoY;
            this.currentSituation[1].compare = this.globalObj["emissions-overview"].value.totalEmissionYoY;
            this.currentSituation[2].compare = this.globalObj["emissions-overview"].value.totalEnergyStrengthYoY;
            this.currentSituation[3].compare = this.globalObj["emissions-overview"].value.totalEnergyYoY;
            console.log(this.currentSituation);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 获取过去二十年的年份
    getPast20Years() {
      let years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 15; i++) {
        const year = currentYear - i;
        years.push(year);
      }
      this.past20Years = years.reverse();
    },
  },
  // watch: {
  //   data: {
  //     async handler() {
  //       await this.getData();
  //     }
  //   }
  // }
};
</script>

<style lang="scss" scoped>
.left-right-chart {
  display: flex;
  justify-content: space-between;
  > .left,
  > .right {
    width: 438px;
    position: fixed;
    top: 119px;
    left: 30px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    left: auto;
    right: 30px;
    display: flex;
    flex-direction: column;
    align-items: end;
  }
}

.footer {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 356px;
  z-index: 1000;

  .footer-main {
    width: 100%;
    padding: 8px 0;
    display: flex;

    span {
      width: 64px;
      display: inline-block;
      font-size: 16px;
      font-family: DIN-Medium, DIN;
      color: #defffa;
      padding: 0px 8px;
      line-height: 34px;
      margin-right: 47px;
      border-radius: 4px;
      cursor: pointer;
    }

    span:hover,
    .yearActive {
      background: #3dfd83;
      border-radius: 4px;
      text-align: center;
      color: #01302d;
    }
  }
}

.current-situation {
  height: 294px;
  background-image: url("../../assets/images/box3.png");

  .current-situation-main {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin: 8px;
      width: 198px;
      height: 103px;
      background: rgba($color: #ffffff, $alpha: 0.1);
      border-radius: 4px;
      padding: 16px 0px 0px 16px;
      .name {
        font-weight: 600;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #d6ffff;
      }

      .value {
        display: flex;
        margin-top: 8px;
        align-items: center;
        div {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d3dede;
          line-height: 12px;
        }
        .s1 {
          font-size: 24px;
          font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
          font-weight: 600;
          color: #3dfd83;
          line-height: 24px;
          margin-right: 8px;
        }
      }

      .compare {
        font-size: 12px;
        color: #defffa;
        margin-top: 8px;

        .s1 {
          font-size: 14px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #eb3838;
          vertical-align: middle;
          margin-left: 4px;
        }
        .s2 {
          font-size: 14px;
          font-family: DIN-Bold, DIN;
          color: #3dfd83;
          vertical-align: middle;
          margin-left: 4px;
        }
      }
    }
  }
}

.departmental-characteristics {
  height: 276px;
  background: url("../../assets//images/page3-item2.png");
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}

.energy-characteristics {
  height: 301px;
  background: url("../../assets/images/page2-item2.png");
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}
.box1 {
  width: 100%;
  height: 226px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.problem-analysis {
  height: 295px;
  background: url("../../assets/images/page2-item1.png");
  .problem-analysis-main {
    padding: 20px 30px;
  }

  .btns {
    margin-bottom: 20px;
    display: flex;
    justify-content: end;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      padding: 0 30px;
      background: rgba($color: #fff, $alpha: 0.2);
      border-radius: 4px;
      font-size: 14px;
      color: #defffa;
      cursor: pointer;
      margin-left: 10px;
    }

    > span:hover,
    .active {
      background: #3dfd83;
      color: #0e3a35;
    }
  }
}

.energy-variety {
  height: 282px;
  background: url("../../assets/images/page3-item5.png");
  box-sizing: border-box;
  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}

.footprint-analysis {
  height: 300px;
  background: url("../../assets/images/page2-item2.png");

  span {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #d6ffff;
    line-height: 20px;
  }
  .unit {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 17px;
  }
}
</style>
