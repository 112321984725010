<template>
  <div class="page1 public-chunk" v-if="show">
    <div class="public-title pad">
      <div class="box">
        <span class="s1">{{title.title}}</span>
        <span>{{title.title1}}</span>
        <div class="animation">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <span>碳排总量</span>
        <p v-scroll-number="chartsData.headers.totalEmission"></p>
        <div>万吨CO2</div>
      </div>
      <div>
        <span>单位GDP碳排强度</span>
        <p v-scroll-number="chartsData.headers.totalEmissionStrength"></p>
        <div>万吨CO2/万元</div>
      </div>
      <div>
        <span>能源消耗总量</span>
        <p v-scroll-number="chartsData.headers.totalEnergy"></p>
        <div>万吨标煤</div>
      </div>
      <div>
        <span>能耗强度</span>
        <p v-scroll-number="chartsData.headers.totalEnergyStrength"></p>
        <div>吨标煤/万元</div>
      </div>
    </div>
    <LineCharts :className="'page3s'" :height="'160px'" width="480px" :areaStyle="['#3dfd83']" :chartsData="chartsData.chartsData" :legendIcon="'square'" />
  </div>
</template>
    
    <script>
import LineCharts from "../Charts/LineChart.vue";
export default {
  components: { LineCharts },
  props: {
    title: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    chartsData: {
      type: Object,
      default: () => {}
    },
    popupX: {
      type: String,
      default: "50%"
    },
    popupY: {
      type: String,
      default: "50%"
    }
  },

  methods: {
    close() {
      this.$emit("close", false);
    }
  }
};
</script>
    
    <style lang="scss" scoped>
.close {
  cursor: pointer;
}
.page1 {
  padding: 0px 8px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 280px;
  background: linear-gradient(
    359deg,
    rgba(2, 68, 50, 0.7) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      165deg,
      rgba(0, 208, 129, 0),
      rgba(0, 207, 126, 1)
    )
    1 1;
  .pad {
    padding: 0px;
    height: 41px;
  }
  .box {
    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #c4f6ee;
      line-height: 20px;
    }
    .s1 {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #d6ffff;
      line-height: 20px;
      margin-right: 16px;
      max-width: 370px;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*禁止换行*/
      text-overflow: ellipsis; /*省略号*/
    }
  }
  .content {
    width: 460px;
    height: 88px;
    background: linear-gradient(359deg, #20442e 0%, rgba(11, 36, 31, 0.2) 100%);
    border-radius: 4px;
    margin-bottom: 8px;
    display: flex;

    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #d6ffff;
        line-height: 14px;
      }
      p {
        font-size: 18px;
        font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
        font-weight: 600;
        color: #3dfd83;
        line-height: 24px;
        margin: 8px 0px;
      }
      div {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d3dede;
        line-height: 12px;
      }
    }
  }
  > p {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #d3dede;
    line-height: 12px;
    text-align: right;
  }
}
</style>