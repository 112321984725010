<template>
  <div class="header_container">
    <div class="title-box">
      <div class="nav-box">
        <span
          @click="navChange(index)"
          v-for="(item, index) in navs"
          :key="index"
          :class="[active === index ? 'active' : '']"
          >{{ item }}</span
        >
      </div>
      <h2>{{ name }}</h2>
      <div class="right">
        <!-- <img src="@/assets/images/icon/sun.png" /> -->
        <span class="s4">{{ weather }}</span>
        <div class="line"></div>
        <span class="s1">{{ maxDegree }}°C</span>
        <span class="s2">重庆</span>
        <span class="s3">{{ time }}</span>
        <span @click="goCarbonMapping" class="btn" v-show="showBtn"
          >进入政碳通</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    name: {
      type: String,
      default: true,
    },
  },
  data() {
    return {
      navs: ["全域态势", "双碳预测", "排放现状", "碳效分析"],
      active: 0,
      time: "",
      weather: "",
      minDegree: "",
      maxDegree: "",
      showBtn: false,
    };
  },

  mounted() {
    //   let url = {
    //     homeView: 0,
    //     peakingPrediction: 1,
    //     emissionStatus: 2,
    //     dynamicEvaluation: 3,
    //   }
    //   this.active = url[this.$route.name]
    const _this = this;
    window.addEventListener("message", function (e) {
      if (e.data == "showBtnGoCarbonMapping") {
        _this.showBtn = true;
      }
    });
    axios
      .get(
        "https://api.seniverse.com/v3/weather/now.json?key=Sewk0QkMxtODfpaj7&location=chongqing"
      )
      .then((res) => {
        this.weather = res.data.results[0].now.text;
        this.maxDegree = res.data.results[0].now.temperature;
      });
    setInterval(() => {
      this.currentTime();
    }, 1000);
  },
  methods: {
    currentTime() {
      const now = new Date();
      const year = now.getFullYear().toString();
      const month = (now.getMonth() + 1).toString().padStart(2, "0");
      const day = now.getDate().toString().padStart(2, "0");
      const hours = now.getHours().toString().padStart(2, "0");
      const minutes = now.getMinutes().toString().padStart(2, "0");
      const seconds = now.getSeconds().toString().padStart(2, "0");
      this.time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    navChange(index) {
      if (index === this.active) return;
      this.active = index;
      switch (index) {
        case 0:
          this.$router.push("/HomeView");
          break;
        case 1:
          this.$router.push("/PeakingPrediction");
          break;
        case 2:
          this.$router.push("/EmissionStatus");
          break;
        case 3:
          this.$router.push("/DynamicEvaluation");
          break;
      }
      this.$emit("navChange", index);
      // let url = {
      //   0: '/homeView',
      //   1: '/peakingPrediction',
      //   2: '/emissionStatus',
      //   3: '/dynamicEvaluation',
      // }
      // this.$router.push({ path: url[index] })
    },
    goCarbonMapping() {
      window.parent.postMessage("goCarbonMapping", "*");
    },
  },
  created() {
    this.active = this.$route.meta.active;
  },
};
</script>

<style lang="scss" scoped>
.header_container {
  height: 88px;
  width: 100%;
  background: url("../../assets/images/header-backg.png");
  background-position: center;
  background-size: cover;
  position: absolute;
  z-index: 1000;
}

.title-box {
  height: 86px;
  display: flex;
  justify-content: space-between;
  h2 {
    width: 33.3%;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #d6ffff;
    line-height: 86px;
    letter-spacing: 3px;
  }
}

.nav-box {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  span {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 124px;
    height: 32px;
    line-height: 32px;
    margin-right: 16px;
    background: url("../../assets/images/nav-text-backg.png");
    background-position: center;
    background-size: cover;
  }
  .active {
    background: url("../../assets/images/nav-text-backgs.png");
    font-weight: bold;
    color: #3dfd83;
  }
}

.right {
  width: 33.3%;
  margin-top: 30px;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 24px;
  .btn {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    width: 124px;
    height: 32px;
    line-height: 32px;
    margin-right: 16px;
    background: url("../../assets/images/nav-text-backg1.svg");
    background-position: center;
    background-size: cover;
    &:hover {
      background: url("../../assets/images/nav-text-backgs1.svg");
      font-weight: bold;
      color: #3dfd83;
    }
  }
  > span {
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family: D-DIN-PRO-Regular, D-DIN-PRO;
    font-weight: 400;
    color: #d6ffff;
    margin-right: 24px;
  }
  .s4 {
    margin: 0;
  }
  .line {
    width: 1px;
    height: 14px;
    background: #d6ffff;
    border-radius: 1px;
    opacity: 0.5;
    margin: 0px 16px;
  }
}
</style>
