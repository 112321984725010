<template>
  <div class="progress-bar">
    <div class="bar-item" v-for="(item, index) in data" :key="index">
      <span v-if="type === 1" class="index">{{ index + 1 }}</span>
      <span class="name" :style="`width: ${nameWidth}px`">{{ item.name }}</span>
      <div class="progress">
        <span :style="`width: ${item.value};`"></span>
      </div>
      <div v-if="type === 1" class="residue">
        <span>{{ item.done }}</span>
        <span>/{{ item.general }}</span>
      </div>
      <span v-if="type === 1" class="percent">{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // type 1 默认样式 2为只有name和进度条
    type: {
      type: Number,
      default: 1
    },
    nameWidth: {
      type: Number,
      default: 50
    }
  },
  watch: {
    data: {
      handler(val) {
        this.newData = val;
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      newData: [
        // { name: "能源", value: "52.1%", general: 13, done: 7 },
        // { name: "工业与建筑", value: "75%", general: 4, done: 3 },
        // { name: "服务业与其他", value: "34%", general: 3, done: 1 },
        // { name: "交通运输", value: "50%", general: 2, done: 1 },
        // { name: "居民生活", value: "100%", general: 2, done: 2 }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.progress-bar {
  .bar-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    .index {
      margin-right: 20px;
    }

    .index,
    .percent {
      width: 10px;
      font-size: 16px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #3dfd83;
    }

    .name {
      width: 100px;
      font-size: 14px;
      color: #defffa;
    }

    .progress {
      flex: 1;
      height: 6px;
      background: rgba($color: #fff, $alpha: 0.3);
      border-radius: 6px;
      display: flex;
      align-items: center;
      overflow: hidden;
      span {
        display: inline-block;
        height: 6px;
        background: #3dfd83;
        border-radius: 6px;
        vertical-align: super;
        transition: width 0.5s ease-in-out; /* 过渡效果：宽度变化时平滑过渡 */
        animation: progressAnimation 1s linear; /* 动画效果：无限循环的水平移动 */
      }
    }
    @keyframes progressAnimation {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    .residue {
      width: 40px;
      margin-left: 10px;
      font-size: 16px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #3dfd83;

      > span:nth-child(2) {
        color: #defffa;
      }
    }

    .percent {
      width: 60px;
      text-align: right;
    }
  }
}
</style>