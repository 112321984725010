<template>
  <div style="width: 100%;height: 100%;">
    <div :class="className" :style="{ height: height, width: width }" />
    <div class="legend-box">
      <div class="head">
        总减排比例：
        <span>-69%</span>
      </div>
      <div class="legend-list">
        <div v-for="(item, index) in data" :key="index">
          <span class="icon" :style="{ background: item.itemStyle.color }"></span>
          <span class="name">{{ item.name }}</span>
          <span class="lower">{{ item.lower }}</span>
          <span class="value">{{ item.value }}%</span>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>
  
<script>
const echarts = require("echarts");
import resize from "./resize";

const animationDuration = 1000;
import { toRaw } from "vue";

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: "chart"
    },
    // 图表宽
    width: {
      type: String,
      default: "100%"
    },
    // 图表高
    height: {
      type: String,
      default: "240px"
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          legend: [],
          data: [
            { value: 720, name: "能源" },
            { value: 720, name: "工业与建筑" },
            { value: 760, name: "交通运输" },
            { value: 520, name: "服务业与其他" },
            { value: 520, name: "农业" },
            { value: 520, name: "居民生活" }
          ]
        };
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return [
          "#4E77DD",
          "#00C4E1",
          "#3DFD83",
          "#F3E141",
          "#DE8800",
          "#952518"
        ];
      }
    },
    // 设置饼图大小
    radius: {
      type: [String, Array],
      default: () => {
        return "50%";
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: "万吨"
    },
    // 类型
    roseType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      const _this = this;

      // 定义自动轮播的数据项和索引
      const data = this.chartsData.data;
      let currentIndex = 0;

      toRaw(this.chart).setOption({
        tooltip: {
          trigger: "item",
          formatter: function(params) {
            var result =
              params.marker + params.name + ": " + params.value + `<br>`;
            return result;
          }
        },
        legend: {
          bottom: "16",
          data: _this.chartsData.legend
        },
        color: _this.colors,
        graphic: {
          type: "text",
          left: "center",
          top: "100",
          y: "20",
          style: _this.isShowSum
            ? {
                text: ["{fontWeight|1500万}", "\n", "tCO2"].join(""),
                rich: {
                  fontWeight: {
                    fontWeight: "bold",
                    fontSize: 16
                  }
                },
                textAlign: "center",
                fill: "#333",
                width: 30,
                height: 30,
                fontSize: 14
              }
            : {}
        },
        series: {
          type: "pie",
          top: -20,
          radius: _this.radius,
          data: _this.chartsData.data,
          avoidLabelOverlap: true,
          label: {
            show: true,
            formatter: function(param) {
              return (
                "{name|" +
                param.name +
                "}\n{value|" +
                param.value +
                "}  {percent|" +
                param.percent +
                "%}"
              );
            },
            rich: {
              name: {
                fontSize: 12,
                // fontWeight: 'bold',
                lineHeight: 15,
                color: "#fff"
              }
            }
          }
        }
      });
      // // 自动轮播tooltip和鼠标悬停效果
      // setInterval(() => {
      //   // 切换到下一个数据项
      //   currentIndex = (currentIndex + 1) % data.length

      //   // 高亮当前数据项
      //   this.chart?.dispatchAction({
      //     type: 'highlight',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   })

      //   // 显示当前数据项的tooltip
      //   this.chart?.dispatchAction({
      //     type: 'showTip',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   })

      //   // 延时一段时间后取消高亮和隐藏tooltip
      //   setTimeout(() => {
      //     this.chart?.dispatchAction({
      //       type: 'downplay',
      //       seriesIndex: 0,
      //       dataIndex: currentIndex
      //     })
      //     this.chart?.dispatchAction({
      //       type: 'hideTip'
      //     })
      //   }, 2500) // 延时2秒后取消高亮和隐藏tooltip
      // }, 3000) // 每3秒切换一次数据项
    }
  }
};
</script>


<style lang="scss" scoped>
.legend-box {
  margin-top: -90px;

  .head {
    text-align: center;
    font-size: 12px;
    color: #d6ffff;
    margin-bottom: 10px;

    span {
      font-size: 20px;
      font-family: DIN-Bold, DIN;
      font-weight: bold;
      color: #3dfd83;
    }
  }

  .legend-list {
    padding-left: 30px;

    > div {
      display: inline-block;
      width: 45%;
      margin-bottom: 4px;

      &:nth-child(2n) {
        margin-left: 10%;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .icon {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 6px;
      }

      .name {
        font-size: 12px;
        color: #d6ffff;
        width: 80px;
      }

      .lower,
      .value {
        font-size: 14px;
        font-family: DIN-Medium, DIN;
        color: #3dfd83;
      }

      .value {
        color: #d6ffff;
        margin-left: 8px;
      }
    }
  }
}
</style>