<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
    
<script>
const echarts = require('echarts')
import resize from './resize'

const animationDuration = 1000
import { toRaw } from 'vue';

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: 'sankeyChart'
    },
    // 图表宽
    width: {
      type: String,
      default: '100%'
    },
    // 图表高
    height: {
      type: String,
      default: '190px'
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          data: [
            {
              name: '原煤',
              itemStyle: {
                color: '#4E77DD'
              },
            }, {
              name: '洗精煤',
              itemStyle: {
                color: '#DE8800'
              },
            }, {
              name: '焦煤',
              itemStyle: {
                color: '#EC505D'
              },
            }, {
              name: '柴油',
              itemStyle: {
                color: '#83A4B6'
              },
            }, {
              name: 'a',
              itemStyle: {
                color: '#4E77DD'
              },
            }, {
              name: 'b',
              itemStyle: {
                color: '#00C4E1'
              },
            }, {
              name: 'c',
              itemStyle: {
                color: '#3DFD83'
              },
            }, {
              name: 'd',
              itemStyle: {
                color: '#952518'
              },
            }],
            links: [{
            source: '原煤',
            target: 'a',
            value: 9,
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          }, {
            source: '原煤',
            target: 'b',
            value: 6,
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }

          }, {
            source: '原煤',
            target: 'c',
            value: 8,
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          }, {
            source: '原煤',
            target: 'd',
            value: 9,
            lineStyle: {
              color: 'source',
              opacity: 0.6
            }
          },
          {
            source: '洗精煤',
            target: 'a',
            value: 2,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '洗精煤',
            target: 'b',
            value: 2,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '洗精煤',
            target: 'c',
            value: 2,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '洗精煤',
            target: 'd',
            value: 9,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '焦煤',
            target: 'a',
            value: 8,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          }, {
            source: '焦煤',
            target: 'b',
            value: 7,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '焦煤',
            target: 'c',
            value: 10,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '焦煤',
            target: 'd',
            value: 7,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },

          {
            source: '柴油',
            target: 'a',
            value: 4,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '柴油',
            target: 'b',
            value: 1,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '柴油',
            target: 'c',
            value: 2,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },
          {
            source: '柴油',
            target: 'd',
            value: 3,
            lineStyle: {
              color: 'source',
              opacity: 0.5
            }
          },]
        }
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ['#F3E141', '#3DFD83', '#00FBE7', '#DE8800']
      }
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      const _this = this
      
      toRaw(this.chart).setOption({
        color: _this.colors,
        series: {
          type: 'sankey',
          layout: 'none',
          right: 30,
          left: 30,
          label: {
            show: false
          },
          focusNodeAdjacency: 'allEdges',
          data: _this.chartsData.data,
          links: _this.chartsData.links
        }
      })
    }
  }
}
</script>