<template>
  <div class="bar-chart" :class="className" :style="{height: height, width: width }"></div>
</template>
  
  <script>
const echarts = require("echarts");
import resize from "./resize";

const animationDuration = 1000;
import { toRaw } from "vue";
export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: "lineChart"
    },
    // 图表宽
    width: {
      type: String,
      default: "100%"
    },
    // 图表高
    height: {
      type: String,
      default: "263px"
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ["#F3E141", "#3DFD83", "#00FBE7", "#DE8800"];
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: "万吨CO2"
    },
    // 折线图是否平滑显示
    isSmooth: {
      type: Boolean,
      default: false
    },
    // 渐变
    areaStyle: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 图表离容器距离
    gridTop: {
      type: Number,
      default: 30
    },
    // 图例样式
    legendIcon: {
      type: String,
      default: "linear"
    }
  },
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    chartsData: {
      handler(val, val1) {
        this.$nextTick(() => {
          this.initChart();
        });
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 计算最大值 均分刻度线
    findMaxAndRoundUp(arr) {
      if (!arr) return;
      const flatArr = arr.flat();
      const maxValue = Math.max(...flatArr);

      let newMax = Number(String(maxValue).charAt(0))
        ? Number(String(maxValue).charAt(0)) + 1
        : 0;
      for (let i = 0; i < String(parseInt(maxValue)).length - 1; i++) {
        newMax += "0";
      }

      return newMax || 100;
    },
    createSeries() {
      const arr = [];
      if (this.chartsData.data && this.chartsData.data.length > 0) {
        this.chartsData.data.forEach((item, index) => {
          const name = this.chartsData.legend[index];
          arr.push({
            name: name,
            type: "pictorialBar", // 保持类型为 bar
            data: item,
            animationDuration,
            symbol: "triangle",
            itemStyle: {
              // 设置 itemStyle
              borderRadius: [0, 0, 0, 0], // 设置顶部边缘半径
              shadowBlur: 10, // 设置阴影模糊度
              shadowColor: "rgba(0, 0, 0, 0.3)", // 设置阴影颜色
              color: {
                type: "linear",
                // image: new echarts.graphic.SvgPath("M0,10 L10,0 L20,10 Z"),
                // repeat: "repeat",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(55, 230, 112,1)"
                  },
                  { offset: 1, color: "rgba(27, 97, 36,.4)" }
                ]
              }
            },
            barWidth: 25, // 将barWidth属性的值调整为较小的值，例如8
            lineStyle: {
              width: 1.5
            },
            label: {
              show: true, // 显示标签
              position: "top", // 标签位置，可以选择 "top", "insideTop" 等
              distance: 10, // 标签距离柱子顶部的距离
              formatter: function(params) {
                // 标签内容格式化函数
                return params.value;
              },
              textStyle: {
                color: "#fff", // 标签文字颜色
                fontSize: 12 // 标签文字大小
              }
            },
            areaStyle: this.areaStyle.length
              ? {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: this.areaStyle[index]
                          ? this.areaStyle[index]
                          : "rgba(39, 194, 143, 0)"
                      },
                      { offset: 1, color: "rgba(0, 0, 0, 0)" }
                    ]
                  }
                }
              : null,
            markLine:
              this.className === "carbon-neutral-chart"
                ? {
                    symbol: [0, 0],
                    data: [
                      {
                        xAxis: 1,
                        label: {
                          show: true,
                          formatter: "达峰年\n2030",
                          backgroundColor: "#F3E141",
                          borderRadius: 4,
                          padding: [5, 13],
                          distance: 5
                        },
                        lineStyle: {
                          normal: {
                            color: "#fff",
                            type: "dashed",
                            opacity: 0.4
                          }
                        }
                      },
                      {
                        xAxis: 4,
                        label: {
                          show: true,
                          formatter: "净零排放碳中和\n2060",
                          backgroundColor: "#3DFD83",
                          borderRadius: 4,
                          padding: [5, 13],
                          distance: 5,
                          color: "#fff",
                          fontSize: "14",
                          fontWeight: "bold"
                        },
                        lineStyle: {
                          normal: {
                            color: "#fff",
                            type: "dashed",
                            opacity: 0.4
                          }
                        }
                      }
                    ]
                  }
                : this.className === "peaking-prediction-line-chart"
                ? {
                    symbol: [0, 0],
                    data: [
                      {
                        xAxis: 2,
                        label: {
                          show: true,
                          formatter: "达峰年\n2030",
                          backgroundColor: "#F3E141",
                          borderRadius: 4,
                          padding: [5, 13],
                          distance: 5,
                          color: "#fff",
                          fontSize: "14",
                          fontWeight: "bold"
                        },
                        lineStyle: {
                          normal: {
                            color: "#fff",
                            type: "dashed",
                            opacity: 0.4
                          }
                        }
                      }
                    ]
                  }
                : {}
          });
        });
      }
      return arr;
    },
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      const _this = this;
      toRaw(this.chart).setOption({
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2, 4, 3, 0.8)",

          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
          },
          borderColor: "#43c87b",
          textStyle: {
            color: "#DEFFFA"
          },
          formatter: function(params) {
            let units = {
              GDP: "　",
              人口总量: "万人",
              能源消耗总量: "万吨标煤",
              碳排放总量: "万吨CO2"
            };
            var result = params[0].axisValue + "<br>"; // 添加标题 "2016"
            params.forEach(function(item) {
              // 使用 <span> 标签包裹 item.value，添加样式为红色
              result += `${item.marker} ${
                params.length === 1 ? item.name : item.seriesName
              }: 
              <span style="color: #3DFD83;font-size: 16px;margin-right: 10px;"> ${
                item.value
              }</span>
              ${
                units[item.seriesName] ? units[item.seriesName] : _this.unit
              }<br>`;
            });
            return result;
          }
        },
        borderColor: "#0C7972",
        textStyle: {
          color: "#DEFFFA"
        },
        legend: {
          bottom: 16,
          right: 16,
          itemWidth: _this.legendIcon === "square" ? 12 : 20,
          itemHeight: _this.legendIcon === "square" ? 12 : 2,
          icon: "roundRect",
          textStyle: {
            color: "#fff", // 图例文本颜色
            fontSize: 12 // 图例文本字体大小
          },
          data: _this.chartsData.legend
        },
        color: this.colors,
        grid: {
          top: _this.gridTop,
          left: "2%",
          right: 10,
          bottom:
            _this.chartsData.legend && _this.chartsData.legend.length
              ? "44"
              : "8",
          containLabel: true,
          show: true,
          borderColor: "none"
        },
        xAxis: {
          type: "category",
          data: _this.chartsData.label,
          axisTick: {
            barStyle: {
              opacity: 0
            },
            axisLine: false,
            axisLabel: {
              lineHeight: 12,
              textStyle: {
                fontSize: "12px",
                color: "#D3DEDE" // 坐标值得具体的颜色
              },
              interval: 1,
              rotate: 0
            }
          }
        },
        yAxis: [
          {
            type: "value",
            max: _this.findMaxAndRoundUp(_this.chartsData?.data),
            interval: parseInt(
              _this.findMaxAndRoundUp(_this.chartsData?.data) / 6
            ),
            axisLabel: {
              color: "#D3DEDE"
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: ["rgba(214, 255, 255, 0.2)"]
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: ["rgba(250,250,250,0.0)", "rgba(214, 255, 255, 0.1)"]
              }
            }
          }
        ],
        series: _this.createSeries()
      });
    }
  }
};
</script>
  
  <style scoped>
.bar-chart {
  height: 100%;
}
</style>