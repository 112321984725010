<template>
<div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
// import echarts from 'echarts'
const echarts = require('echarts');
import resize from './resize'

const animationDuration = 1000
import { toRaw } from 'vue';

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: 'barChartFalls'
    },
    // 图表宽
    width: {
      type: String,
      default: '100%'
    },
    // 图表高
    height: {
      type: String,
      default: '248px'
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          label: ['1', '2', '3', '4', '5', '6', '7', '8'],
          legend: ['碳排值', '碳吸收', '碳吸收1'],
          data: [
            [0, 32, 16, 10, 6, 3, 2, 0],
            [64],
            ['-', 32],
            ['-', '-', 16],
            ['-', '-', '-', 10],
            ['-', '-', '-', '-', 6],
            ['-', '-', '-', '-', '-', 3],
            ['-', '-', '-', '-', '-', '-', 2],
          ]
        }
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ['#4E77DD', '#00C4E1', '#3DFD83', '#F3E141', '#DE8800', '#952518']
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: '万吨CO2'
    },
    // 图表离容器距离
    gridTop: {
      type: Number,
      default: 30
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    // 计算最大值 均分刻度线
    findMaxAndRoundUp (arr) {
      if (!arr) return
      const flatArr = arr.flat()
      const maxValue = Math.max(...flatArr)

      let newMax = Number(String(maxValue).charAt(0)) ? Number(String(maxValue).charAt(0)) + 1 : 0
      for (let i = 0; i < String(parseInt(maxValue)).length - 1; i++) {
        newMax += '0'
      }


      return newMax || 100
    },
    // 生成Series
    createSeries () {
      const arr = []
      this.chartsData.data.forEach((item, index) => {
        const name = this.chartsData.legend[index]
        arr.push({
          name: name,
          type: 'bar',
          data: item,
          stack: 'Total',
          silent: true,
          animationDuration,
          barWidth: 30,
          label: index !== 0 ? {
            show: true,
            position: 'top',
          } : { show: false },
          itemStyle: index === 0 ? {
            borderColor: 'transparent',
            color: 'transparent'
          } : {},
          emphasis: index === 0 ? {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent'
            }
          } : {},
        })
      })
      return arr
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      const _this = this

      // 定义自动轮播的数据项和索引
      const data = this.chartsData.label
      let currentIndex = 0
      toRaw(this.chart).setOption({
        tooltip: {
          show: false
        },
        legend: { show: null },
        color: _this.colors,
        grid: {
          top: _this.gridTop,
          left: '2%',
          right: 30,
          bottom: _this.chartsData.legend.length ? '44' : '8',
          containLabel: true,
          show: true,
          borderColor: 'none'
        },
        xAxis: [{
          type: 'category',
          data: _this.chartsData.label,
          axisbar: {
            barStyle: {
              opacity: 0
            }
          },
          axisLine: false,
          axisLabel: {
            show: false
          },
        }],
        yAxis: [{
          type: 'value',
          // max: _this.findMaxAndRoundUp(_this.chartsData?.data),
          // interval: parseInt(_this.findMaxAndRoundUp(_this.chartsData?.data) / 6),
          axisLabel: {
            color: '#fff'
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        }],
        series: _this.createSeries()
      })

    }
  }
}
</script>