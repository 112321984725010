import { createStore } from 'vuex'

const store = createStore({
    state() {
        return {
            id: null,
            scale: 0,
            year: '',
            page3year: ''
        }
    },
    mutations: {
        setId(state, data) {
            state.id = data
        },
        setScale(state, data) {
            state.scale = data
        },
        setYear(state, data) {
            state.year = data
        },
        setPage3Year(state, data) {
            state.page3year = data
        }
    },
    actions: {
    },
    getters: {

    }
})

export default store