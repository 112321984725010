import { createRouter, createWebHistory } from 'vue-router';
import layout from './components/Map/index.vue'
const routes = [
    {
        path: '/',
        component: layout,
        redirect: '/HomeView',
        children: [
            {
                path: '/HomeView', meta: {
                    active: 0,
                    from: 'HomeView',
                }, component: () => import('./components/HomeView/index.vue')
            },
            {
                path: '/PeakingPrediction', meta: {
                    active: 1,
                    from: 'PeakingPrediction',
                }, component: () => import('./components/peakingPrediction/index.vue')
            },
            {
                path: '/EmissionStatus', meta: {
                    active: 2,
                    from: 'EmissionStatus',
                }, component: () => import('./components/emissionStatus/index.vue')
            },
            {
                path: '/DynamicEvaluation', meta: {
                    active: 3,
                    from: 'DynamicEvaluation',
                }, component: () => import('./components/dynamicEvaluation/index.vue')
            },
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;