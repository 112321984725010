<template>
  <div class="dynamic-evaluation">
    <div class="greenhouse-gases public-chunk">
      <div class="public-title">
        <div class="box">
          <span>{{globalObj['UPTAKE-PJ']&&globalObj['UPTAKE-PJ'].dataName}}</span>
          <div class="animation">
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="item">
          <p>碳吸收项目总数</p>
          <h2 v-scroll-number="absorption2.value"></h2>
          <span>(单位：{{ absorption2.unit }})</span>
        </div>
        <div class="line"></div>
        <div class="item">
          <p>已测算项目</p>
          <h2 class="h2" v-scroll-number="absorption1.value"></h2>
          <span>(单位：{{ absorption1.unit }})</span>
        </div>
        <div class="line"></div>
        <div class="item">
          <p>累计碳吸收量</p>
          <h2 class="h3" v-scroll-number="absorption&&absorption.value"></h2>
        </div>
      </div>
      <div class="charts">
        <div class="hear">
          <div>
            <span class="line"></span>
            <span>
              {{
              globalObj["UPTAKE-PJ-CLASS"]&&globalObj["UPTAKE-PJ-CLASS"].dataName
              }}
            </span>
          </div>
          <span>单位:万吨CO2</span>
        </div>
        <ProgressBar :data="progressBarData" :type="1" />
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
import ProgressBar from "@/components/Charts/ProgressBar.vue";
import { str } from "../../../utils/index";
export default {
  components: { LineChart, ProgressBar },
  props: {
    globalObj: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      absorption: { value: 0 },
      absorption1: { value: 0 },
      absorption2: { value: 0 },
      greenhouseGasesData: {
        label: ["1月", "2月", "3月", "4月", "5月", "六月", "七月"],
        legend: ["温室气体浓度", "卫星监测浓度"],
        data: [
          [234, 251, 243, 216, 255, 244, 263, 231, 253, 246],
          [398, 393, 389, 402, 387, 389, 383, 396, 403, 395]
        ]
      },
      progressBarData: [
        // { name: "能源", value: "52.1%", general: 13, done: 7 },
        // { name: "工业与建筑", value: "75%", general: 4, done: 3 },
        // { name: "服务业与其他", value: "34%", general: 3, done: 1 },
        // { name: "交通运输", value: "50%", general: 2, done: 1 },
        // { name: "居民生活", value: "100%", general: 2, done: 2 }
      ]
    };
  },
  watch: {
    globalObj: {
      handler(val) {
        if (this.globalObj["UPTAKE-PJ"]) {
          this.absorption = str(this.globalObj["UPTAKE-PJ"].value.carbonUptake);
          this.absorption1 = str(
            this.globalObj["UPTAKE-PJ"].value.calculateNum
          );
          this.absorption2 = str(
            this.globalObj["UPTAKE-PJ"].value.uptakeTotalNum
          );
          this.progressBarData = this.globalObj["UPTAKE-PJ-CLASS"].value;
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.greenhouse-gases {
  height: 376px;
  width: 438px;
  background-image: url("../../../assets//images//box2.png");
  background-size: cover;
  .name {
    font-size: 14px;
    font-weight: 600;
    color: #defffa;
    padding: 11px 0px 0px 17px;
  }
  > .center {
    width: 392px;
    height: 74px;
    margin: 28px auto 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .item {
      width: 30%;
      height: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #c4f6ee;
        line-height: 14px;
      }
      > h2 {
        font-size: 32px;
        font-family: D-DIN-PRO-SemiBold, D-DIN-PRO;
        font-weight: 600;
        color: #f3e141;
        margin: 8px 0px;
      }
      .h2 {
        color: #09e8f8;
      }
      .h3 {
        color: #3dfd83;
      }
      > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d3dede;
        line-height: 12px;
      }
    }
    .line {
      width: 1px;
      height: 54px;
      background-image: url("../../../assets/images/line.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .charts {
    width: 404px;
    h: 180px;

    margin: 40px auto 0px;
    .hear {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      > div {
        display: flex;
        align-items: center;
        .line {
          display: block;
          width: 2px;
          height: 14px;
          background: linear-gradient(180deg, #70ffb9 0%, #338e54 100%);
          border-radius: 2px;
          margin-right: 8px;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d6ffff;
        }
      }
      > span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #d3dede;
      }
    }
  }
}

.carbon-emission {
  padding: 20px 30px;
  margin-top: 10px;
  height: 143px;

  .name {
    font-size: 14px;
    font-weight: 600;
    color: #defffa;
    line-height: 14px;
    margin-bottom: 20px;
  }
}
</style>