import { createApp, provide, ref } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import AMapLoader from 'vue3-amap';
import { scrollNumberDirective } from './utils/index'
import router from './router.js'
import store from './store/index'
//引入css
// import './assets/css/style.css'
// import './assets/css/globel.css'




const app = createApp(App)
app.config.globalProperties.eventBus = app;
app.use(ElementPlus)
app.use(AMapLoader)
app.use(router)
app.use(store)
app.directive('scroll-number', scrollNumberDirective);
app.mount('#app');
