<template>
  <!-- 左右两边的图表 -->
  <div class="left-right-chart">
    <div class="left">
      <div class="sum" @click="sideClick('', 10)" :class="[active === 10 ? 'active1' : '']">
        <div class="sever1">
          <img src="../../assets/images/icon/item0s.png" alt v-if="active == 10" />
          <img src="../../assets/images/icon/item0.png" alt v-else />
        </div>
        <div class="text">全部</div>
      </div>
      <div class="btns">
        <div class="item" @click="sideClick(item, i)" v-for="(item, i) in classList" :key="item.industryId" :class="[active === i ? 'active' : '']">
          <div v-if="i !== 5">
            <img :src="item.img" v-if="active !== i" />
            <img :src="item.img1" v-else />
          </div>
          <div v-else class="box">
            <div class="sever" v-if="active !== i">
              <img src="../../assets/images/icon/item7a.png" alt />
            </div>
            <div v-else class="sever1">
              <img src="../../assets/images/icon/item7sa.png" alt />
            </div>
          </div>
          <div class="industryName">{{ item.industryName }}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <!-- 区域碳效占比 -->
      <div class="regional-carbon-emission public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["EFFICIENCY"] && globalObj["EFFICIENCY"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
          <span />
        </div>
        <PieRingCharts :className="'regionalEarbonEmissionData'" :chartsData="regionalEarbonEmissionData" :colors="[]" height="250px"></PieRingCharts>
      </div>
      <!-- 企业碳监测 -->
      <div class="enterprise-carbon-testing public-chunk">
        <div class="public-title">
          <div class="box">
            <span>{{ globalObj["ENT-EFFICIENCY"] && globalObj["ENT-EFFICIENCY"].dataName }}</span>
            <div class="animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          </div>
        </div>
        <div class="data-list">
          <div class="header">
            <span class="column1">排名</span>
            <span class="column2">企业</span>
            <div class="column3">
              <span>碳效值</span>
            </div>
            <span class="column4">碳效码</span>
          </div>
          <div class="list-body">
            <div class="list-item" v-for="(item, index) in dataList" :key="index">
              <span class="column1">{{ index + 1 }}</span>
              <el-tooltip class="box-item" effect="dark" :content="item.name ? item.name : '---'" placement="top-start">
                <span class="column2">{{ item.name ? item.name : "---" }}</span>
              </el-tooltip>

              <span class="column3">{{ item.value ? item.value : "---" }}</span>
              <div class="column4">
                <img :src="item.code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-main">
        <span v-for="(year, index) in past20YearsSorted" :key="year" @click="changeYear(index, year)" :class="[yearActive === index ? 'yearActive' : '']">{{ year }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "@/components/Charts/LineChart.vue";
import PieRingCharts from "../Charts/PieRingCharts.vue";
import Map from "@/components/Map/index.vue";
import axios from "axios";
import item2 from "../../assets/images/icon/item2.png";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    const setId = (data) => {
      store.commit("setId", data);
    };
    const setYear = (data) => {
      store.commit("setYear", data);
    };
    return { setId, setYear };
  },
  components: { LineChart, Map, PieRingCharts },
  data() {
    return {
      year: new Date().getFullYear(),
      item2: item2,
      past20Years: [],
      classList: [],
      yearActive: 0,
      active: 10,
      regionalEarbonEmissionData: [
        // { value: 3, name: "调入电力" },
        // { value: 1, name: "碳酸钾" },
        // { value: 2, name: "碳酸钾" }
      ],
      testingActive: 1,
      dataList: [],
      globalObj: {},
      data: [],
      industryId: null,
    };
  },
  // 监听属性 类似于data概念
  computed: {
    past20YearsSorted() {
      return this.past20Years.reverse();
    },
    defaultOption() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.getPast20Years();
  },
  created() {
    sessionStorage.setItem("year", this.year);
    const a = JSON.parse(sessionStorage.getItem("data"));
    this.data = a[3] || [];
    this.getData();
  },
  methods: {
    changeYear(index, year) {
      this.yearActive = index;
      this.year = year;
      this.setYear(this.year);
      sessionStorage.setItem("year", this.year);
      this.getData(year, this.industryId);
      // this.setTime(year);
    },
    sideClick(data, i) {
      this.active = i;
      if (i == 10) {
        this.industryId = "";
        this.getData(this.year);
      } else {
        this.industryId = data.industryId;
        this.getData(this.year, data.industryId);
      }
      this.setId(this.industryId);
    },
    // 获取过去二十年的年份
    getPast20Years() {
      let years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 0; i < 15; i++) {
        const year = currentYear - i;
        years.push(year);
      }
      this.past20Years = years.reverse();
    },
    getData(year = new Date().getFullYear(), id) {
      const obj = {};
      if (this.data && this.data.list.length > 0) {
        const promiseList = [];
        this.data.list.forEach((citem) => {
          citem.poList.forEach((gitem) => {
            gitem.key = gitem.keyValue;
            obj[gitem.key] = gitem;
            if (obj[gitem.key].functionName == "carbonEfficiencyAnalysis") {
              const years = [year];
              if (id) {
                years.push(id);
              }
              const promise = axios
                .post("https://government.etantong.com/government/configurationDetail/fetch-data/" + obj[gitem.key].id, years, {
                  headers: {
                    token:
                      sessionStorage.getItem("token") ||
                      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                  },
                })
                .then((res) => {
                  obj[gitem.key].value = JSON.parse(res.data.data.data);
                });
              promiseList.push(promise);
            } else if (obj[gitem.key].type != "1" && obj[gitem.key].type != "4") {
              obj[gitem.key].value = JSON.parse(obj[gitem.key].data);
            } else {
              obj[gitem.key].value = obj[gitem.key].data;
            }
          });
        });
        Promise.all(promiseList)
          .then(() => {
            console.log(this.globalObj);
            this.globalObj = obj;
            this.classList = this.globalObj["EFFICIENCY"].value.industryList;
            console.log(this.classList);
            this.classList.forEach((item, index) => {
              item.img = require(`@/assets/images/icon/item${index + 2}.png`);
              item.img1 = require(`@/assets/images/icon/item${index + 2}s.png`);
            });
            let data1 = 0;
            let data2 = 0;
            let data3 = 0;
            let data4 = 0;
            let data5 = 0;
            this.globalObj["EFFICIENCY"].value.codePieViews.forEach((item) => {
              if (item.codeLevel == "1") {
                data1 = item.codeCount;
              } else if (item.codeLevel == "2") {
                data2 = item.codeCount;
              } else if (item.codeLevel == "3") {
                data3 = item.codeCount;
              } else if (item.codeLevel == "4") {
                data4 = item.codeCount;
              } else if (item.codeLevel == "5") {
                data5 = item.codeCount;
              }
            });
            this.regionalEarbonEmissionData = [];

            if (data1 > 0) {
              this.regionalEarbonEmissionData.push({
                value: data1,
                name: "绿码",
                itemStyle: { color: "#38f17f" },
              });
            }
            if (data2 > 0) {
              this.regionalEarbonEmissionData.push({
                value: data2,
                name: "蓝码",
                itemStyle: { color: "#13edfa" },
              });
            }
            if (data3 > 0) {
              this.regionalEarbonEmissionData.push({
                value: data3,
                name: "黄码",
                itemStyle: { color: "#f9ef6c" },
              });
            }
            if (data4 > 0) {
              this.regionalEarbonEmissionData.push({
                value: data4,
                name: "橙码",
                itemStyle: { color: "#f0d031" },
              });
            }
            if (data5 > 0) {
              this.regionalEarbonEmissionData.push({
                value: data5,
                name: "红码",
                itemStyle: { color: "#ff653d" },
              });
            }
            this.dataList = this.globalObj["EFFICIENCY"].value.carbonEmissions.map((item, index) => {
              if (item.codeLevel == "1") {
                const code = "";
              } else if (item.codeLevel == "2") {
                data2++;
              } else if (item.codeLevel == "3") {
                data3++;
              } else if (item.codeLevel == "4") {
                data4++;
              } else if (item.codeLevel == "5") {
                data5++;
              }
              return {
                name: item.mainName,
                leval: index,
                code: item.codeLogo,
                value: item.carbonEfficiency,
              };
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 356px;
  z-index: 1000;

  .footer-main {
    width: 100%;
    padding: 8px 0;
    display: flex;

    span {
      padding: 0px 8px;
      width: 64px;
      display: inline-block;
      font-size: 16px;
      font-family: DIN-Medium, DIN;
      color: #defffa;
      line-height: 34px;
      margin-right: 47px;
      border-radius: 4px;
      cursor: pointer;
    }

    span:hover,
    .yearActive {
      background: #3dfd83;
      border-radius: 4px;
      color: #01302d;
      text-align: center;
    }
  }
}
.none {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.left-right-chart {
  > .right {
    width: 438px;
    position: fixed;
    height: 900px;
    top: 119px;
    left: 30px;
    z-index: 10000;
  }

  .right {
    left: auto;
    right: 30px;
  }
}
.left {
  width: 70px;
  position: fixed;
  height: 900px;
  top: 119px;
  left: 30px;
  z-index: 10000;
  .sum {
    width: 50px;
    height: 72px;
    margin-bottom: 20px;
    cursor: pointer;

    .sever1 {
      width: 50px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d6ffff;
      text-align: center;
    }
  }
  .sum:hover {
    .text {
      color: #3dfd83;
    }
  }
}
.btns {
  > .item {
    width: 50px;
    height: 72px;
    border-radius: 4px;
    margin-bottom: 20px;
    > img {
      width: 50px;
      height: 50px;
    }
    .industryName {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d6ffff;
    }
    .box {
      width: 50px;
      height: 50px;
    }
    .sever {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../../assets/images/icon/item7.png");
    }
    .sever1 {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("../../assets/images/icon/item7s.png");
    }
    cursor: pointer;
    text-align: center;
  }

  > div:hover,
  .active {
    font-weight: 600;
    .industryName {
      font-weight: 600;
      color: #3dfd83;
    }
  }
}
.active1 {
  .text {
    font-weight: 600 !important;
    color: #3dfd83 !important;
  }
}
.regional-carbon-emission {
  height: 295px;
  background: url("../../assets/images/page2-item1.png");
  .unit {
    text-align: right;
    height: 10px;
    color: #c4f6ee;
    margin-top: 10px;
    padding-right: 30px;
    font-size: 12px;
    color: #c4f6ee;
  }
}

.enterprise-carbon-testing {
  height: 596px;
  background-image: url("../../assets/images/page4-item2.png");
  background-size: cover;

  .btns {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 8px 30px;

    span {
      width: 88px;
      height: 28px;
      background: rgba($color: #fff, $alpha: 0.2);
      border-radius: 4px;
      margin-left: 10px;
      text-align: center;
      line-height: 28px;
      font-size: 14px;
      color: #d6ffff;
      cursor: pointer;
    }

    > span:hover,
    .testingActive {
      background: #3dfd83;
      font-size: #01302d;
    }
  }
}

.data-list {
  padding: 0 20px;
  height: 520px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 40px;
    margin-top: 16px;
    background: rgba(61, 253, 131, 0.15);
    span {
      font-size: 12px;
      color: #d6ffff;
    }
  }

  .column1 {
    flex: 1;
  }

  .column2 {
    flex: 3;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .column3 {
    flex: 2;
    color: #3dfd83 !important;
    p {
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #d3dede;
    }
  }

  .column4 {
    flex: 1;
    img {
      width: 14px;
      height: 14px;
    }
  }

  .list-body {
    text-align: center;
    height: 430px;
    overflow: auto;

    .list-item {
      display: flex;
      align-items: center;
      height: 35px;
      span {
        height: 35px;
        line-height: 35px;
        font-size: 12px;
        color: #d6ffff;
      }

      .leval1 {
        color: #3dfd83;
      }

      .leval2 {
        color: #00c4e1;
      }

      .leval3 {
        color: #f3e141;
      }

      .leval4 {
        color: #ff8140;
      }

      .leval5 {
        color: #ee1111;
      }
    }
  }
}

.seamless-warp {
  position: relative;
  overflow: hidden;
  // overflow-y: auto;
  height: 430px;
}
</style>
