<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
    
  <script>
const echarts = require("echarts");
import resize from "./resize";

const animationDuration = 1000;
import { toRaw } from "vue";

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: "lineChart"
    },
    // 图表宽
    width: {
      type: String,
      default: "100%"
    },
    // 图表高
    height: {
      type: String,
      default: "240px"
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return [
          { value: 2, name: "调入电力" },
          { value: 3, name: "天然气" },
          { value: 7, name: "碳酸钾" },
          { value: 1, name: "煤" }
        ];
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {}
    },
    // 数值单位
    unit: {
      type: String,
      default: "个"
    },
    // 折线图是否平滑显示
    isSmooth: {
      type: Boolean,
      default: false
    },
    // 渐变
    areaStyle: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 图表离容器距离
    gridTop: {
      type: Number,
      default: 30
    },
    // 图例样式
    legendIcon: {
      type: String,
      default: "linear"
    }
  },
  data() {
    return {
      chart: null
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  watch: {
    chartsData: {
      handler() {
        if (this.colors && this.colors.length != 0) {
          if (this.chartsData.length > 0) {
            this.chartsData.forEach((item, i) => {
              if (i <= this.colors.length - 1) {
                item.itemStyle.color = this.colors[i];
              } else {
                item.itemStyle.color = this.generateUniqueColors(1)[0];
              }
            });
          }
        } else {
          if (this.chartsData.length > 0) {
            this.chartsData.forEach((item, i) => {
              if (!item.itemStyle.color) {
                item.itemStyle.color = this.generateUniqueColors(1)[0];
              }
            });
          }
        }
        this.initChart();
      }
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons", { renderer: "svg" });
      const _this = this;
      // 定义自动轮播的数据项和索引
      const data = this.chartsData.label;
      toRaw(this.chart).setOption({
        tooltip: {
          trigger: "item"
        },
        legend: {
          bottom: "10%",
          left: "center",
          textStyle: {
            color: "#fff"
          }
        },
        color: _this.colors,

        series: [
          {
            type: "pie",
            radius: ["40%", "50%"],
            center: ["50%", "36%"], // 将圆环向上移动10个像素
            avoidLabelOverlap: true,
            startAngle: 160,
            label: {
              show: true,
              color: "#fff",
              width: 100,
              formatter: function(params) {
                if (_this.className == "regionalEarbonEmissionData") {
                  return `{b|${params.name}}  {d| ${params.percent}%}`;
                } else {
                  return `{a|${params.name}}\n{b|${params.data.value}}{c|${
                    _this.unit
                  }}{c| ${params.percent.toFixed(2)}%}`;
                }
              },
              rich: {
                a: {
                  fontSize: 10,
                  lineHeight: 12,
                  color: "#fff"
                },
                b: {
                  fontSize: 10,
                  lineHeight: 12,
                  color: "#15f5fc", // 将数据部分的颜色设置为橙色
                  fontWeight: "bold"
                },
                c: {
                  fontSize: 10,
                  lineHeight: 12,
                  color: "#fff"
                }
              }
            },
            labelLine: {
              show: true, // 显示标签线条
              length: 15, // 标签线条长度
              lineStyle: {
                color: "rgba(214, 255, 255, 0.4)" // 标签线条颜色
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 12,
                fontWeight: "bold"
              }
            },
            data: this.chartsData
          }
        ]
      });
    },
    // 随机颜色
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";

      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }

      return color;
    },
    generateUniqueColors(numColors) {
      const uniqueColors = [];

      while (uniqueColors.length < numColors) {
        const color = this.getRandomColor();

        if (!uniqueColors.includes(color)) {
          uniqueColors.push(color);
        }
      }

      return uniqueColors;
    }
  }
};
</script>

