<template>
  <div class="homa">
    <div id="mapContainer" ref="ScaleBox"></div>
  </div>
</template>

<script>
import { load } from "@amap/amap-jsapi-loader";
import axios from "axios";
import item1 from "@/assets/images/icon/corporation1.svg";
import item2 from "@/assets/images/icon/corporation2.svg";
import item3 from "@/assets/images/icon/corporation3.svg";
import item4 from "@/assets/images/icon/corporation4.svg";
import item5 from "@/assets/images/icon/corporation5.svg";
import item6 from "@/assets/images/icon/corporation6s.svg";
// import item1s from "@/assets/images/icon/corporation1s.png";
// import item2s from "@/assets/images/icon/corporation2s.png";
// import item3s from "@/assets/images/icon/corporation3s.png";
// import item4s from "@/assets/images/icon/corporation4s.png";
// import item5s from "@/assets/images/icon/corporation5s.png";
import item6s from "@/assets/images/icon/corporation6s.png";
import page1 from "../dialog/page1/page1.vue";
import page2 from "../dialog/page2.vue";
import page3 from "../dialog/page3.vue";
import page4 from "../dialog/page4.vue";
import { createApp, h, computed, watch, ref } from "vue";
import { scrollNumberDirective } from "../../utils/index";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const id = computed(() => store.state.id);
    const year = computed(() => store.state.year);
    const scale = computed(() => store.state.scale);
    const page3year = computed(() => store.state.page3year);
    let companyList = ref({});
    let page1Shows = ref(false);
    let page2Shows = ref(false);
    let page3Shows = ref(false);
    let page4Shows = ref(false);
    let title = ref({});
    let chartsData = ref({ label: [], data: [], legend: [] });
    let chartsData2 = ref({});
    let chartsData3 = ref({ headers: {}, chartsData: { data: [], label: [] } });
    let chartsData4 = ref({});
    let markericons = ref([]);
    let texts = ref([]);
    let text = ref([]);
    let map1 = ref(null);
    const $route = useRoute();
    const currentYear = ref("2024");

    // 刷新地图
    const getdata = () => {
      axios({
        method: "get",
        url: `https://government.etantong.com/government/configurationDetail/queryAccountBySupervise`,
        headers: {
          // token: queryParamsObject.token
          token:
            sessionStorage.getItem("token") ||
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
        },
        params: {
          industryId: id.value,
          year: year.value,
        },
      }).then((res) => {
        companyList.value.value = res.data.data;
        // 高德地图API的加载参数
        const loaderOptions = {
          key: "ec418b8d61253097038a42b3d46824f3",
          version: "1.4.15",
          plugins: ["AMap.Satellite"],
        }; // 加载高德地图API
        load(loaderOptions).then((AMap) => {
          // 创建地图实例
          const map = new AMap.Map("mapContainer", {
            zoom: 14,
            center: [106.500099, 29.61594],
            features: [],

            mapStyle: "amap://styles/2d3e3f7b9a6b6f5b6c7d7e0f2f3f4f7f8?showLabels=false&showGrid=false", // 使用暗色主题
            scrollWheel: true, // 关闭缩放
          });
          map1.value = map;

          // 添加卫星图层
          const markers = map.setMapStyle("amap://styles/empty");
          const satelliteLayer = new AMap.TileLayer.Satellite({
            HdTraffic: true, // 开启高清影像
          });
          map.add(satelliteLayer);
          const markers1 = companyList.value.value.map((item, index) => {
            switch (item.codeLevel) {
              case 1:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#3DFD83",
                  icon: new AMap.Icon({
                    image: item1,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item1,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 2:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#09E8F8",

                  icon: new AMap.Icon({
                    image: item3,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item3,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 3:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#F3E141",
                  icon: new AMap.Icon({
                    image: item4,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item4,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 4:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#FFB826",

                  icon: new AMap.Icon({
                    image: item2,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item2,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 5:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#FF5732",

                  icon: new AMap.Icon({
                    image: item5,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item5,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              default:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#fff",
                  icon: new AMap.Icon({
                    image: item6s,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item6,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
            }
          });
          const infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
            isCustom: true,
          });
          let markericons1 = [];
          let texts = [];
          let text = [];
          for (let i = 0; i < markers1.length; i++) {
            const marker = new AMap.Marker({
              position: markers1[i].position,
              icon: markers1[i].icon,
            });

            markericons1.push(
              new AMap.Marker({
                position: markers1[i].position,
                icon: markers1[i].icons,
                animation: "AMAP_ANIMATION_DROP",
              })
            );
            texts.push(
              new AMap.Text({
                text: markers1[i].text, // 设置标注文本内容
                position: markers1[i].position, // 设置标注位置坐标
                offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                style: {
                  fontSize: "12px", // 设置字体大小
                  fontWeight: "400", // 设置字体加粗
                  backgroundColor: "transparent", // 设置文本背景为透明
                  color: markers1[i].color, // 设置文本颜色为白色
                  border: 0, //取消边框
                  fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                },
              })
            );
            marker.on("click", (e) => {
              marker.setOpacity(0);
              map.remove(text[i]);
              const data = markers1[i];
              markericons1.forEach((item, index) => {
                if (index != i) {
                  map.remove(item);
                }
              });
              texts.forEach((item, index) => {
                if (index != i) {
                  map.remove(item);
                }
              });
              text.forEach((item, index) => {
                map.remove(item);
              });
              text.forEach((item, index) => {
                if (index != i) {
                  item.setMap(map);
                }
              });
              switch ($route.meta.active) {
                case 0:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      title.value.title = data.text;
                      title.value.title1 = "碳排变化趋势";
                      page1Shows.value = true;
                      // this.showPage1(true);
                      infoWindow.open(map, marker.getPosition());
                      const datalist = res.data.data.map((item) => item.totalEmission);
                      (chartsData.value.label = res.data.data.map((item) => item.year)), (chartsData.value.data = [datalist]);
                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page1, {
                            // 传递参数给组件
                            chartsData: chartsData.value,
                            title: title.value,
                            show: page1Shows.value,
                            // page1Show: _this.close
                          }),
                      });
                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                  }
                  break;
                case 1:
                  {
                    const years = [sessionStorage.getItem("year"), data.codeId];
                    axios
                      .post("https://government.etantong.com/government/configurationDetail/fetch-data/1706862598061359105", years, {
                        headers: {
                          // token: queryParamsObject.token
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                      })
                      .then((res) => {
                        page2Shows.value = true;
                        title.value.title = data.text;
                        title.value.title1 = "双碳预测";
                        const datalist = JSON.parse(res.data.data.data);
                        chartsData2.value.data = datalist.data;
                        chartsData2.value.label = datalist.label.slice(1, -1).split(",");
                        chartsData2.value.legend = JSON.parse(datalist.legend);
                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page2, {
                              // 传递参数给组件
                              chartsData: chartsData2.value,
                              title: title.value,
                              show: page2Shows.value,
                              // page1Show: _this.close
                            }),
                        });
                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                  }
                  break;
                case 2:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsOverview",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      chartsData3.value.headers = {
                        totalEmission: res.data.data.totalEmission,
                        totalEmissionStrength: res.data.data.totalEmissionStrength,
                        totalEnergy: res.data.data.totalEnergy,
                        totalEnergyStrength: res.data.data.totalEnergyStrength,
                      };
                      axios({
                        headers: {
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                        url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                        method: "get",
                        params: {
                          year: sessionStorage.getItem("year"),
                          accountId: data.codeId,
                        },
                      }).then((res) => {
                        page3Shows.value = true;
                        title.value.title = data.text;
                        title.value.title1 = "碳排现状";
                        const data1 = res.data.data.map((item) => item.totalEmission);
                        chartsData3.value.chartsData.data = [data1];
                        chartsData3.value.chartsData.label = res.data.data.map((item) => item.year);
                        chartsData3.value.chartsData.legend = [];
                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page3, {
                              // 传递参数给组件
                              chartsData: chartsData3.value,
                              title: title.value,
                              show: page3Shows.value,
                              // page1Show: _this.close
                            }),
                        });
                        app.directive("scroll-number", scrollNumberDirective);
                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                    });
                  }
                  break;
                case 3:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEfficiency",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      page4Shows.value = true;
                      title.value.title = data.text;
                      title.value.title1 = "碳效分析";
                      if (res.data.data) {
                        chartsData4.value = {
                          codeLevel: data.codeLevel,
                          totalEmission: res.data.data.carbonEfficiency ? res.data.data.carbonEfficiency : 0,
                          totalEmissionStrength: res.data.data.totalEmission ? res.data.data.totalEmission : 0,
                          totalEnergy: res.data.data.carbonIntensity ? res.data.data.carbonIntensity : 0,
                          totalEnergyStrength: res.data.data.industryAverage ? res.data.data.industryAverage : 0,
                        };
                      } else {
                        chartsData4.value = null;
                      }

                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page4, {
                            // 传递参数给组件
                            chartsData: chartsData4.value,
                            title: title.value,
                            show: page4Shows.value,
                            // page1Show: _this.close
                          }),
                      });
                      app.directive("scroll-number", scrollNumberDirective);

                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                  }
                  break;
              }
              markericons1[i].setMap(map);
              texts[i].setMap(map);
              setTimeout(() => {
                marker.setOpacity(1);
              }, 500);
              // 执行其他操作...
            });

            // 绑定地图容器的点击事件
            map.on("click", (e) => {
              map.remove(texts[i]);
              map.remove(markericons1[i]);
              text[i].setMap(map);
              infoWindow.close();
            });
            // 创建文本标注
            text.push(
              new AMap.Text({
                text: markers1[i].text, // 设置标注文本内容
                position: markers1[i].position, // 设置标注位置坐标
                offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                style: {
                  fontSize: "12px", // 设置字体大小
                  fontWeight: "400", // 设置字体加粗
                  backgroundColor: "transparent", // 设置文本背景为透明
                  color: "#C4F6EE", // 设置文本颜色为白色
                  border: 0, //取消边框
                  fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                },
              })
            );

            markericons.value = markericons1;
            texts.value = texts;
            marker.setMap(map);
            text[i].setMap(map);
          }
        });
      });
    };
    // 监听左侧行业改变
    watch(
      () => id.value,
      () => {
        getdata();
      }
    );

    watch(
      () => scale.value,
      () => {
        const ScaleBox = document.querySelector("#mapContainer");
        if (ScaleBox) {
          ScaleBox.style.scale = 1 / scale.value;
        }
      }
    );
    // 监听年份改变
    watch(
      () => year.value,
      () => {
        getdata();
      }
    );
    // 监听路由变化
    watch(
      () => $route.path,
      () => {
        page1Shows.value = false;
        page2Shows.value = false;
        page3Shows.value = false;
        page4Shows.value = false;
        markericons.value.forEach((item) => {
          map1.value.remove(item);
        });
      }
    );
    // 排放现状改变后刷新地图
    watch(
      () => page3year.value,
      () => {
        axios({
          method: "get",
          url: `https://government.etantong.com/government/configurationDetail/queryAccountBySupervise?year=${this.currentYear}`,
          headers: {
            // token: queryParamsObject.token
            token:
              sessionStorage.getItem("token") ||
              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
          },
          params: {
            industryId: id.value,
            year: page3year.value,
          },
        }).then((res) => {
          companyList.value.value = res.data.data;
          // 高德地图API的加载参数
          const loaderOptions = {
            key: "ec418b8d61253097038a42b3d46824f3",
            version: "1.4.15",
            plugins: ["AMap.Satellite"],
          }; // 加载高德地图API
          load(loaderOptions).then((AMap) => {
            // 创建地图实例
            const map = new AMap.Map("mapContainer", {
              zoom: 14,
              center: [106.500099, 29.61594],
              features: [],

              mapStyle: "amap://styles/2d3e3f7b9a6b6f5b6c7d7e0f2f3f4f7f8?showLabels=false&showGrid=false", // 使用暗色主题
              scrollWheel: true, // 关闭缩放
            });
            map1.value = map;

            // 添加卫星图层
            const markers = map.setMapStyle("amap://styles/empty");
            const satelliteLayer = new AMap.TileLayer.Satellite({
              HdTraffic: true, // 开启高清影像
            });
            map.add(satelliteLayer);
            const markers1 = companyList.value.value.map((item, index) => {
              switch (item.codeLevel) {
                case 1:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#3DFD83",
                    icon: new AMap.Icon({
                      image: item1,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item1,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
                case 2:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#09E8F8",

                    icon: new AMap.Icon({
                      image: item3,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item3,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
                case 3:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#F3E141",
                    icon: new AMap.Icon({
                      image: item4,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item4,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
                case 4:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#FFB826",

                    icon: new AMap.Icon({
                      image: item2,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item2,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
                case 5:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#FF5732",

                    icon: new AMap.Icon({
                      image: item5,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item5,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
                default:
                  return {
                    codeLevel: item.codeLevel,
                    codeId: item.accountId,
                    position: [item.longitude, item.latitude],
                    text: item.mainName,
                    color: "#fff",
                    icon: new AMap.Icon({
                      image: item6s,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                    icons: new AMap.Icon({
                      image: item6,
                      size: [55, 55],
                      imageSize: [60, 60],
                      offset: [-6, -32],
                    }),
                  };
              }
            });
            const infoWindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -30),
              isCustom: true,
            });
            let markericons1 = [];
            let texts = [];
            let text = [];
            for (let i = 0; i < markers1.length; i++) {
              const marker = new AMap.Marker({
                position: markers1[i].position,
                icon: markers1[i].icon,
              });

              markericons1.push(
                new AMap.Marker({
                  position: markers1[i].position,
                  icon: markers1[i].icons,
                  animation: "AMAP_ANIMATION_DROP",
                })
              );
              texts.push(
                new AMap.Text({
                  text: markers1[i].text, // 设置标注文本内容
                  position: markers1[i].position, // 设置标注位置坐标
                  offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                  style: {
                    fontSize: "12px", // 设置字体大小
                    fontWeight: "400", // 设置字体加粗
                    backgroundColor: "transparent", // 设置文本背景为透明
                    color: markers1[i].color, // 设置文本颜色为白色
                    border: 0, //取消边框
                    fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                  },
                })
              );
              marker.on("click", (e) => {
                marker.setOpacity(0);
                map.remove(text[i]);
                const data = markers1[i];
                markericons1.forEach((item, index) => {
                  if (index != i) {
                    map.remove(item);
                  }
                });
                texts.forEach((item, index) => {
                  if (index != i) {
                    map.remove(item);
                  }
                });
                text.forEach((item, index) => {
                  map.remove(item);
                });
                text.forEach((item, index) => {
                  if (index != i) {
                    item.setMap(map);
                  }
                });
                switch ($route.meta.active) {
                  case 0:
                    {
                      axios({
                        headers: {
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                        url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                        method: "get",
                        params: {
                          year: sessionStorage.getItem("year"),
                          accountId: data.codeId,
                        },
                      }).then((res) => {
                        title.value.title = data.text;
                        title.value.title1 = "碳排变化趋势";
                        page1Shows.value = true;
                        // this.showPage1(true);
                        infoWindow.open(map, marker.getPosition());
                        const datalist = res.data.data.map((item) => item.totalEmission);
                        (chartsData.value.label = res.data.data.map((item) => item.year)), (chartsData.value.data = [datalist]);
                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page1, {
                              // 传递参数给组件
                              chartsData: chartsData.value,
                              title: title.value,
                              show: page1Shows.value,
                              // page1Show: _this.close
                            }),
                        });
                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                    }
                    break;
                  case 1:
                    {
                      const years = [sessionStorage.getItem("year"), data.codeId];
                      axios
                        .post("https://government.etantong.com/government/configurationDetail/fetch-data/1706862598061359105", years, {
                          headers: {
                            // token: queryParamsObject.token
                            token:
                              sessionStorage.getItem("token") ||
                              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                          },
                        })
                        .then((res) => {
                          page2Shows.value = true;
                          title.value.title = data.text;
                          title.value.title1 = "双碳预测";
                          const datalist = JSON.parse(res.data.data.data);
                          chartsData2.value.data = datalist.data;
                          chartsData2.value.label = datalist.label.slice(1, -1).split(",");
                          chartsData2.value.legend = JSON.parse(datalist.legend);
                          // 创建应用实例
                          const app = createApp({
                            render: () =>
                              h(page2, {
                                // 传递参数给组件
                                chartsData: chartsData2.value,
                                title: title.value,
                                show: page2Shows.value,
                                // page1Show: _this.close
                              }),
                          });
                          const container = document.createElement("div");
                          app.mount(container);
                          infoWindow.setContent(container);
                          infoWindow.open(map, marker.getPosition());
                        });
                    }
                    break;
                  case 2:
                    {
                      axios({
                        headers: {
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                        url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsOverview",
                        method: "get",
                        params: {
                          year: sessionStorage.getItem("year"),
                          accountId: data.codeId,
                        },
                      }).then((res) => {
                        console.log(res);
                        chartsData3.value.headers = {
                          totalEmission: res.data.data.totalEmission,
                          totalEmissionStrength: res.data.data.totalEmissionStrength,
                          totalEnergy: res.data.data.totalEnergy,
                          totalEnergyStrength: res.data.data.totalEnergyStrength ? res.data.data.totalEnergyStrength : 0,
                        };
                        axios({
                          headers: {
                            token:
                              sessionStorage.getItem("token") ||
                              "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                          },
                          url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                          method: "get",
                          params: {
                            year: sessionStorage.getItem("year"),
                            accountId: data.codeId,
                          },
                        }).then((res) => {
                          page3Shows.value = true;
                          title.value.title = data.text;
                          title.value.title1 = "碳排现状";
                          const data1 = res.data.data.map((item) => item.totalEmission);
                          chartsData3.value.chartsData.data = [data1];
                          chartsData3.value.chartsData.label = res.data.data.map((item) => item.year);
                          chartsData3.value.chartsData.legend = [];
                          // 创建应用实例
                          const app = createApp({
                            render: () =>
                              h(page3, {
                                // 传递参数给组件
                                chartsData: chartsData3.value,
                                title: title.value,
                                show: page3Shows.value,
                                // page1Show: _this.close
                              }),
                          });
                          app.directive("scroll-number", scrollNumberDirective);
                          const container = document.createElement("div");
                          app.mount(container);
                          infoWindow.setContent(container);
                          infoWindow.open(map, marker.getPosition());
                        });
                      });
                    }
                    break;
                  case 3:
                    {
                      axios({
                        headers: {
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                        url: "https://government.etantong.com/government/configurationDetail/enterpriseEfficiency",
                        method: "get",
                        params: {
                          year: sessionStorage.getItem("year"),
                          accountId: data.codeId,
                        },
                      }).then((res) => {
                        page4Shows.value = true;
                        title.value.title = data.text;
                        title.value.title1 = "碳效分析";
                        if (res.data.data) {
                          chartsData4.value = {
                            codeLevel: data.codeLevel,
                            totalEmission: res.data.data.carbonEfficiency ? res.data.data.carbonEfficiency : 0,
                            totalEmissionStrength: res.data.data.totalEmission ? res.data.data.totalEmission : 0,
                            totalEnergy: res.data.data.carbonIntensity ? res.data.data.carbonIntensity : 0,
                            totalEnergyStrength: res.data.data.industryAverage ? res.data.data.industryAverage : 0,
                          };
                        } else {
                          chartsData4.value = null;
                        }

                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page4, {
                              // 传递参数给组件
                              chartsData: chartsData4.value,
                              title: title.value,
                              show: page4Shows.value,
                              // page1Show: _this.close
                            }),
                        });
                        app.directive("scroll-number", scrollNumberDirective);

                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                    }
                    break;
                }
                markericons1[i].setMap(map);
                texts[i].setMap(map);
                setTimeout(() => {
                  marker.setOpacity(1);
                }, 500);
                // 执行其他操作...
              });

              // 绑定地图容器的点击事件
              map.on("click", (e) => {
                map.remove(texts[i]);
                map.remove(markericons1[i]);
                text[i].setMap(map);
                infoWindow.close();
              });
              // 创建文本标注
              text.push(
                new AMap.Text({
                  text: markers1[i].text, // 设置标注文本内容
                  position: markers1[i].position, // 设置标注位置坐标
                  offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                  style: {
                    fontSize: "12px", // 设置字体大小
                    fontWeight: "400", // 设置字体加粗
                    backgroundColor: "transparent", // 设置文本背景为透明
                    color: "#C4F6EE", // 设置文本颜色为白色
                    border: 0, //取消边框
                    fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                  },
                })
              );

              markericons.value = markericons1;
              texts.value = texts;
              marker.setMap(map);
              text[i].setMap(map);
            }
          });
        });
      }
    );
    return {
      id,
      companyList,
      scale,
      markericons,
      texts,
      text,
      map1,
      page1Shows,
      page2Shows,
      page3Shows,
      page4Shows,
      year,
    };
  },
  data() {
    return {
      popupX: 0,
      popupY: 0,
      markers: [],
      page1Show: false,
      page2Show: false,
      page3Show: false,
      page4Show: false,
      title: {},

      chartsData: {
        label: [],
        data: [],
        legend: [],
      },
      chartsData2: {},
      chartsData3: {
        headers: {},
        chartsData: {},
      },
      chartsData4: {},
    };
  },
  components: { page1, page2, page3, page4 },
  beforeCreate() {
    this.currentYear = new Date().getFullYear();
    console.log("this.currentYear", this.currentYear);
  },
  // 加载地图
  mounted() {
    axios({
      method: "get",
      url: `https://government.etantong.com/government/configurationDetail/queryAccountBySupervise?year=${this.currentYear}`,
      headers: {
        // token: queryParamsObject.token
        token:
          sessionStorage.getItem("token") ||
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
      },
    }).then((res) => {
      const { data } = res.data;
      // 使用一个对象来存储每个 accountId 对应的最新数据
      const latestEntries = {};
      data.forEach((item) => {
        latestEntries[item.accountId] = item;
      });
      // 将对象中的值转换为数组，得到最终结果
      this.companyList.value = Object.values(latestEntries);
      // 高德地图API的加载参数
      const _this = this;
      const loaderOptions = {
        key: "ec418b8d61253097038a42b3d46824f3",
        version: "1.4.15",
        plugins: ["AMap.Satellite"],
      }; // 加载高德地图API
      let map = null;
      load(loaderOptions).then((AMap) => {
        // 创建地图实例
        map = new AMap.Map("mapContainer", {
          zoom: 14,
          center: [106.500099, 29.61594],
          features: [],
          mapStyle: "amap://styles/2d3e3f7b9a6b6f5b6c7d7e0f2f3f4f7f8?showLabels=false&showGrid=false", // 使用暗色主题
          scrollWheel: true, // 关闭缩放
        });
        this.map1 = map;
        const markers = map.setMapStyle("amap://styles/empty");
        const satelliteLayer = new AMap.TileLayer.Satellite({
          HdTraffic: true, // 开启高清影像
        });
        map.add(satelliteLayer);
        const markers1 = this.companyList.value.map((item, index) => {
          switch (item.codeLevel) {
            case 1:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#3DFD83",
                icon: new AMap.Icon({
                  image: item1,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item1,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
            case 2:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#09E8F8",

                icon: new AMap.Icon({
                  image: item3,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item3,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
            case 3:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#F3E141",
                icon: new AMap.Icon({
                  image: item4,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item4,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
            case 4:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#FFB826",

                icon: new AMap.Icon({
                  image: item2,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item2,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
            case 5:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#FF5732",

                icon: new AMap.Icon({
                  image: item5,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item5,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
            default:
              return {
                codeLevel: item.codeLevel,
                codeId: item.accountId,
                position: [item.longitude, item.latitude],
                text: item.mainName,
                color: "#fff",
                icon: new AMap.Icon({
                  image: item6s,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
                icons: new AMap.Icon({
                  image: item6,
                  size: [55, 55],
                  imageSize: [60, 60],
                  offset: [-6, -32],
                }),
              };
          }
        });
        const infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          isCustom: true,
        });
        let markericons = [];
        let texts = [];
        let text = [];
        for (let i = 0; i < markers1.length; i++) {
          const marker = new AMap.Marker({
            position: markers1[i].position,
            zIndex: 9999,
            icon: markers1[i].icon,
          });
          markericons.push(
            new AMap.Marker({
              position: markers1[i].position,
              icon: markers1[i].icons,
              zIndex: 9999,
              animation: "AMAP_ANIMATION_DROP",
            })
          );
          texts.push(
            new AMap.Text({
              text: markers1[i].text, // 设置标注文本内容
              position: markers1[i].position, // 设置标注位置坐标
              offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
              style: {
                fontSize: "12px", // 设置字体大小
                fontWeight: "400", // 设置字体加粗
                backgroundColor: "transparent", // 设置文本背景为透明
                color: markers1[i].color, // 设置文本颜色为白色
                border: 0, //取消边框
                fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
              },
            })
          );
          marker.on("click", (e) => {
            marker.setOpacity(0); // 点击时将透明度设置为0
            map.remove(text[i]);
            markericons[i].setMap(map);
            texts[i].setMap(map);
            markericons.forEach((item, index) => {
              if (index != i) {
                map.remove(item);
              }
            });
            texts.forEach((item, index) => {
              if (index != i) {
                map.remove(item);
              }
            });
            text.forEach((item, index) => {
              map.remove(item);
            });
            text.forEach((item, index) => {
              if (index != i) {
                item.setMap(map);
              }
            });
            const data = markers1[i];
            switch (_this.$route.meta.active) {
              case 0:
                {
                  axios({
                    headers: {
                      token:
                        sessionStorage.getItem("token") ||
                        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                    },
                    url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                    method: "get",
                    params: {
                      year: sessionStorage.getItem("year"),
                      accountId: data.codeId,
                    },
                  }).then((res) => {
                    _this.title.title = data.text;
                    _this.title.title1 = _this.page1Tit = "碳排变化趋势";
                    _this.page1Show = true;
                    // this.showPage1(true);
                    infoWindow.open(map, marker.getPosition());
                    const datalist = res.data.data.map((item) => item.totalEmission);
                    (_this.chartsData.label = res.data.data.map((item) => item.year)), (_this.chartsData.data = [datalist]);
                    // 创建应用实例
                    const app = createApp({
                      render: () =>
                        h(page1, {
                          // 传递参数给组件
                          chartsData: _this.chartsData,
                          title: _this.title,
                          show: _this.page1Show,
                          // page1Show: _this.close
                        }),
                    });
                    const container = document.createElement("div");
                    app.mount(container);
                    infoWindow.setContent(container);
                    infoWindow.open(map, marker.getPosition());
                  });
                }
                break;
              case 1:
                {
                  const years = [sessionStorage.getItem("year"), data.codeId];
                  axios
                    .post("https://government.etantong.com/government/configurationDetail/fetch-data/1706862598061359105", years, {
                      headers: {
                        // token: queryParamsObject.token
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                    })
                    .then((res) => {
                      _this.page2Show = true;
                      _this.title.title = data.text;
                      _this.title.title1 = _this.page1Tit = "双碳预测";
                      const datalist = JSON.parse(res.data.data.data);
                      _this.chartsData2.data = datalist.data;
                      _this.chartsData2.label = datalist.label.slice(1, -1).split(",");
                      _this.chartsData2.legend = JSON.parse(datalist.legend);
                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page2, {
                            // 传递参数给组件
                            chartsData: _this.chartsData2,
                            title: _this.title,
                            show: _this.page2Show,
                            // page1Show: _this.close
                          }),
                      });
                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                }
                break;
              case 2:
                {
                  axios({
                    headers: {
                      token:
                        sessionStorage.getItem("token") ||
                        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                    },
                    url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsOverview",
                    method: "get",
                    params: {
                      year: sessionStorage.getItem("year"),
                      accountId: data.codeId,
                    },
                  }).then((res) => {
                    _this.chartsData3.headers = {
                      totalEmission: res.data.data.totalEmission,
                      totalEmissionStrength: res.data.data.totalEmissionStrength,
                      totalEnergy: res.data.data.totalEnergy,
                      totalEnergyStrength: res.data.data.totalEnergyStrength,
                    };
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      _this.page3Show = true;
                      _this.title.title = data.text;
                      _this.title.title1 = "碳排现状";
                      const data1 = res.data.data.map((item) => item.totalEmission);
                      _this.chartsData3.chartsData.data = [data1];
                      _this.chartsData3.chartsData.label = res.data.data.map((item) => item.year);
                      _this.chartsData3.chartsData.legend = [];
                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page3, {
                            // 传递参数给组件
                            chartsData: _this.chartsData3,
                            title: _this.title,
                            show: _this.page3Show,
                            // page1Show: _this.close
                          }),
                      });
                      app.directive("scroll-number", scrollNumberDirective);
                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                  });
                }
                break;
              case 3:
                {
                  axios({
                    headers: {
                      token:
                        sessionStorage.getItem("token") ||
                        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                    },
                    url: "https://government.etantong.com/government/configurationDetail/enterpriseEfficiency",
                    method: "get",
                    params: {
                      year: sessionStorage.getItem("year"),
                      accountId: data.codeId,
                    },
                  }).then((res) => {
                    _this.page4Show = true;
                    _this.title.title = data.text;
                    _this.title.title1 = _this.page1Tit = "碳效分析";
                    if (res.data.data) {
                      _this.chartsData4 = {
                        codeLevel: res.data.data.codeLevel ? res.data.data.codeLevel : 1,
                        totalEmission: res.data.data.carbonEfficiency ? res.data.data.carbonEfficiency : 0,
                        totalEmissionStrength: res.data.data.totalEmission ? res.data.data.totalEmission : 0,
                        totalEnergy: res.data.data.carbonIntensity ? res.data.data.carbonIntensity : 0,
                        totalEnergyStrength: res.data.data.industryAverage ? res.data.data.industryAverage : 0,
                        codeLogo: res.data.data.codeLogo ? res.data.data.codeLogo : "",
                        codeName: res.data.data.codeName ? res.data.data.codeName : "",
                      };
                    } else {
                      _this.chartsData4 = null;
                    }
                    // 创建应用实例
                    const app = createApp({
                      render: () =>
                        h(page4, {
                          // 传递参数给组件
                          chartsData: _this.chartsData4,
                          title: _this.title,
                          show: _this.page4Show,
                          // page1Show: _this.close
                        }),
                    });
                    app.directive("scroll-number", scrollNumberDirective);

                    const container = document.createElement("div");
                    app.mount(container);
                    infoWindow.setContent(container);
                    infoWindow.open(map, marker.getPosition());
                  });
                }
                break;
            }
            setTimeout(() => {
              marker.setOpacity(1);
            }, 500);
          });
          // 绑定地图容器的点击事件
          map.on("click", (e) => {
            map.remove(texts[i]);
            map.remove(markericons[i]);
            marker.setOpacity(1); // 点击时将透明度设置为0
            text[i].setMap(map);
            infoWindow.close();
          });
          // 创建文本标注
          text.push(
            new AMap.Text({
              text: markers1[i].text, // 设置标注文本内容
              position: markers1[i].position, // 设置标注位置坐标
              offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
              style: {
                fontSize: "12px", // 设置字体大小
                fontWeight: "400", // 设置字体加粗
                backgroundColor: "transparent", // 设置文本背景为透明
                color: "#C4F6EE", // 设置文本颜色为白色
                border: 0, //取消边框
                fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
              },
            })
          );
          this.markericons.value = markericons;
          this.texts.value = texts;
          this.text.value = text;
          marker.setMap(map);
          text[i].setMap(map);
        }
      });
    });
  },
  // 监听路由变化关闭弹窗等效果
  watch: {
    $route: async function (to, from) {
      this.page1Show = false;
      this.page2Show = false;
      this.page3Show = false;
      this.page4Show = false;
      if (this.markericons.value) {
        this.markericons.value.forEach((item) => {
          this.map1.remove(item);
        });
      }
      if (from.path == "/DynamicEvaluation" || from.path == "/EmissionStatus") {
        this.initMap();
      }
    },
  },

  methods: {
    // 检查像素坐标是否在可见范围内
    initMap() {
      axios({
        method: "get",
        url: `https://government.etantong.com/government/configurationDetail/queryAccountBySupervise?year=${this.currentYear}`,
        headers: {
          // token: queryParamsObject.token
          token:
            sessionStorage.getItem("token") ||
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
        },
      }).then((res) => {
        this.companyList.value = res.data.data;
        // 高德地图API的加载参数
        const _this = this;
        const loaderOptions = {
          key: "ec418b8d61253097038a42b3d46824f3",
          version: "1.4.15",
          plugins: ["AMap.Satellite"],
        }; // 加载高德地图API
        let map = null;
        load(loaderOptions).then((AMap) => {
          // 创建地图实例
          map = new AMap.Map("mapContainer", {
            zoom: 14,
            center: [106.500099, 29.61594],
            features: [],
            mapStyle: "amap://styles/2d3e3f7b9a6b6f5b6c7d7e0f2f3f4f7f8?showLabels=false&showGrid=false", // 使用暗色主题
            scrollWheel: true, // 关闭缩放
          });
          this.map1 = map;
          const markers = map.setMapStyle("amap://styles/empty");
          const satelliteLayer = new AMap.TileLayer.Satellite({
            HdTraffic: true, // 开启高清影像
          });
          map.add(satelliteLayer);
          const markers1 = this.companyList.value.map((item, index) => {
            switch (item.codeLevel) {
              case 1:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#3DFD83",
                  icon: new AMap.Icon({
                    image: item1,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item1,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 2:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#09E8F8",

                  icon: new AMap.Icon({
                    image: item3,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item3,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 3:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#F3E141",
                  icon: new AMap.Icon({
                    image: item4,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item4,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 4:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#FFB826",

                  icon: new AMap.Icon({
                    image: item2,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item2,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              case 5:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#FF5732",

                  icon: new AMap.Icon({
                    image: item5,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item5,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
              default:
                return {
                  codeLevel: item.codeLevel,
                  codeId: item.accountId,
                  position: [item.longitude, item.latitude],
                  text: item.mainName,
                  color: "#fff",
                  icon: new AMap.Icon({
                    image: item6s,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                  icons: new AMap.Icon({
                    image: item6,
                    size: [55, 55],
                    imageSize: [60, 60],
                    offset: [-6, -32],
                  }),
                };
            }
          });
          const infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30),
            isCustom: true,
          });
          let markericons = [];
          let markericons1 = [];
          let texts = [];
          let text = [];

          for (let i = 0; i < markers1.length; i++) {
            const marker = new AMap.Marker({
              position: markers1[i].position,
              zIndex: 9999,
              icon: markers1[i].icon,
            });
            markericons.push(
              new AMap.Marker({
                position: markers1[i].position,
                icon: markers1[i].icons,
                zIndex: 9999,
                animation: "AMAP_ANIMATION_DROP",
              })
            );
            texts.push(
              new AMap.Text({
                text: markers1[i].text, // 设置标注文本内容
                position: markers1[i].position, // 设置标注位置坐标
                offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                style: {
                  fontSize: "12px", // 设置字体大小
                  fontWeight: "400", // 设置字体加粗
                  backgroundColor: "transparent", // 设置文本背景为透明
                  color: markers1[i].color, // 设置文本颜色为白色
                  border: 0, //取消边框
                  fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                },
              })
            );
            marker.on("click", (e) => {
              marker.setOpacity(0); // 点击时将透明度设置为0
              map.remove(text[i]);
              markericons[i].setMap(map);
              texts[i].setMap(map);
              markericons.forEach((item, index) => {
                if (index != i) {
                  map.remove(item);
                }
              });
              texts.forEach((item, index) => {
                if (index != i) {
                  map.remove(item);
                }
              });
              text.forEach((item, index) => {
                map.remove(item);
              });
              text.forEach((item, index) => {
                if (index != i) {
                  item.setMap(map);
                }
              });
              const data = markers1[i];
              switch (_this.$route.meta.active) {
                case 0:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      _this.title.title = data.text;
                      _this.title.title1 = _this.page1Tit = "碳排变化趋势";
                      _this.page1Show = true;
                      // this.showPage1(true);
                      infoWindow.open(map, marker.getPosition());
                      const datalist = res.data.data.map((item) => item.totalEmission);
                      (_this.chartsData.label = res.data.data.map((item) => item.year)), (_this.chartsData.data = [datalist]);
                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page1, {
                            // 传递参数给组件
                            chartsData: _this.chartsData,
                            title: _this.title,
                            show: _this.page1Show,
                            // page1Show: _this.close
                          }),
                      });
                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                  }
                  break;
                case 1:
                  {
                    const years = [sessionStorage.getItem("year"), data.codeId];
                    axios
                      .post("https://government.etantong.com/government/configurationDetail/fetch-data/1706862598061359105", years, {
                        headers: {
                          // token: queryParamsObject.token
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                      })
                      .then((res) => {
                        _this.page2Show = true;
                        _this.title.title = data.text;
                        _this.title.title1 = _this.page1Tit = "双碳预测";
                        const datalist = JSON.parse(res.data.data.data);
                        _this.chartsData2.data = datalist.data;
                        _this.chartsData2.label = datalist.label.slice(1, -1).split(",");
                        _this.chartsData2.legend = JSON.parse(datalist.legend);
                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page2, {
                              // 传递参数给组件
                              chartsData: _this.chartsData2,
                              title: _this.title,
                              show: _this.page2Show,
                              // page1Show: _this.close
                            }),
                        });
                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                  }
                  break;
                case 2:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsOverview",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      _this.chartsData3.headers = {
                        totalEmission: res.data.data.totalEmission,
                        totalEmissionStrength: res.data.data.totalEmissionStrength,
                        totalEnergy: res.data.data.totalEnergy,
                        totalEnergyStrength: res.data.data.totalEnergyStrength,
                      };
                      axios({
                        headers: {
                          token:
                            sessionStorage.getItem("token") ||
                            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                        },
                        url: "https://government.etantong.com/government/configurationDetail/enterpriseEmissionsYearLine",
                        method: "get",
                        params: {
                          year: sessionStorage.getItem("year"),
                          accountId: data.codeId,
                        },
                      }).then((res) => {
                        _this.page3Show = true;
                        _this.title.title = data.text;
                        _this.title.title1 = "碳排现状";
                        const data1 = res.data.data.map((item) => item.totalEmission);
                        _this.chartsData3.chartsData.data = [data1];
                        _this.chartsData3.chartsData.label = res.data.data.map((item) => item.year);
                        _this.chartsData3.chartsData.legend = [];
                        // 创建应用实例
                        const app = createApp({
                          render: () =>
                            h(page3, {
                              // 传递参数给组件
                              chartsData: _this.chartsData3,
                              title: _this.title,
                              show: _this.page3Show,
                              // page1Show: _this.close
                            }),
                        });
                        app.directive("scroll-number", scrollNumberDirective);
                        const container = document.createElement("div");
                        app.mount(container);
                        infoWindow.setContent(container);
                        infoWindow.open(map, marker.getPosition());
                      });
                    });
                  }
                  break;
                case 3:
                  {
                    axios({
                      headers: {
                        token:
                          sessionStorage.getItem("token") ||
                          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiIxNjk2MDgzMzY2MDg3NTYxMjE3IiwiYWNjb3VudElkIjoxNjk2MDgzMzY1MTY0ODE0MzM4LCJhY2NvdW50TnVtIjoiMTY5MzIxMjc2MDYwNTczNyIsInBob25lIjoiMTU2MzYzNTI1ODkiLCJpc09wZXJhdGlvbiI6MiwibG9naW5OYW1lIjoibGpzdGhqIiwibmFtZSI6IueOr-S_neWxgCIsIm1haW5OYW1lIjoi6YeN5bqG5Lik5rGf5paw5Yy655Sf5oCB546v5aKD5bGAIiwiaXNBZG1pbiI6MSwiZXhwIjoxNzA1MjAxMzI0LCJ1c2VySWQiOjE2OTYwODMzNjYwODc1NjEyMTd9._eJTs52gr4R1WCp_1obMOkBwqODotlKpRmsPoO74H3A",
                      },
                      url: "https://government.etantong.com/government/configurationDetail/enterpriseEfficiency",
                      method: "get",
                      params: {
                        year: sessionStorage.getItem("year"),
                        accountId: data.codeId,
                      },
                    }).then((res) => {
                      _this.page4Show = true;
                      _this.title.title = data.text;
                      _this.title.title1 = _this.page1Tit = "碳效分析";
                      if (res.data.data) {
                        _this.chartsData4 = {
                          codeLevel: data.codeLevel,
                          totalEmission: res.data.data.carbonEfficiency ? res.data.data.carbonEfficiency : 0,
                          totalEmissionStrength: res.data.data.totalEmission ? res.data.data.totalEmission : 0,
                          totalEnergy: res.data.data.carbonIntensity ? res.data.data.carbonIntensity : 0,
                          totalEnergyStrength: res.data.data.industryAverage ? res.data.data.industryAverage : 0,
                        };
                      } else {
                        _this.chartsData4 = null;
                      }
                      // 创建应用实例
                      const app = createApp({
                        render: () =>
                          h(page4, {
                            // 传递参数给组件
                            chartsData: _this.chartsData4,
                            title: _this.title,
                            show: _this.page4Show,
                            // page1Show: _this.close
                          }),
                      });
                      app.directive("scroll-number", scrollNumberDirective);

                      const container = document.createElement("div");
                      app.mount(container);
                      infoWindow.setContent(container);
                      infoWindow.open(map, marker.getPosition());
                    });
                  }
                  break;
              }
              setTimeout(() => {
                marker.setOpacity(1);
              }, 500);
            });
            // 绑定地图容器的点击事件
            map.on("click", (e) => {
              map.remove(texts[i]);
              map.remove(markericons[i]);
              marker.setOpacity(1); // 点击时将透明度设置为0
              text[i].setMap(map);
              infoWindow.close();
            });
            // 创建文本标注
            text.push(
              new AMap.Text({
                text: markers1[i].text, // 设置标注文本内容
                position: markers1[i].position, // 设置标注位置坐标
                offset: new AMap.Pixel(20, 29), // 设置文本偏移量，使其显示在标点下方
                style: {
                  fontSize: "12px", // 设置字体大小
                  fontWeight: "400", // 设置字体加粗
                  backgroundColor: "transparent", // 设置文本背景为透明
                  color: "#C4F6EE", // 设置文本颜色为白色
                  border: 0, //取消边框
                  fontFamily: " PingFangSC-Regular, PingFang SC", // 设置字体样式为 Arial 或 sans-serif
                },
              })
            );
            this.markericons.value = markericons;
            this.texts.value = texts;
            this.text.value = text;
            marker.setMap(map);
            text[i].setMap(map);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.homa {
  width: 100%;
  height: 100%;
  position: relative;
  #mapContainer {
    position: absolute;
    width: 100%;
    height: 100%;
     z-index: 999;
  }
}
</style>
